import BackButton from "../components/BackButton";
import MenuButtonInactive from "../components/MenuButtonInactive";
import IntroHeading from "../components/IntroHeading";
import IntroDescription from "../components/IntroDescription";
import { useEffect } from 'react'


const AccessibilityStatement = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
        }, []
    )
    
    return (
        <>
            <MenuButtonInactive />
            <BackButton />
            <div className = 'intro-div-surrounding'>
                <div className = 'padding-top-5' />
                <IntroHeading text = 'Accessibility Statement' />
                {/* <IntroHeading text = 'Continuing Bonds' /> */}
                <div className = 'margin-top-3' />
                <div>
                    This statement covers how the mobile app Continuing Bonds Toolkit, published by the University of Bradford on 10th March 2022, meets compliance with the <a href = 'https://www.legislation.gov.uk/uksi/2018/952/contents/made?view=plain'>Public Sector Body (Websites and Mobile Applications) (No. 2) Accessibility Regulations 2018</a>.
                </div>
                <div className = 'margin-top-2'></div>
                <div> 
                    We want as many people as possible to be able to use this application. For example, that means you should be able to: 
                </div>
                <ul>
                    <li>Zoom in up to 200%</li>
                    <li>The mobile application can be used without specific touchscreen or device manipulation gestures</li>
                    <li>Use the application without encountering any flashing, scrolling or moving text</li>
                    <li>Operate the application through written text information, whereby colour and sound is not used to solely convey any information</li>
                    <li>Use with both Android and Apple devices and requisite other options</li>
                    <li>Customising the website</li>
                </ul>
                <div className = 'margin-top-2'></div>
                <div>
                    AbilityNet has advice on making your device easier to use if you have a disability. This is an external site with suggestions to make your computer more accessible:
                </div>
                <div className = 'margin-top-2'></div>
                <div>
                    <a href = 'https://abilitynet.org.uk/'>AbilityNet - My Computer My Way</a>
                </div>
                <div className = 'margin-top-2'></div>
                <IntroDescription text = 'Feedback and contact information' />
                <div className = 'margin-top-2'></div>
                <div>
                    We are always looking to improve the accessibility of this website. If you think we’re not meeting accessibility requirements, please contact: <a href = 'mailto:continuingbonds@bradford.ac.uk'>continuingbonds@bradford.ac.uk</a> We’ll consider your request and get back to you in 5 working days.
                </div>
                <div className = 'margin-top-2'></div>
                <IntroDescription text = 'Enforcement procedure' />
                <div className = 'margin-top-2'></div>
                <div>
                    The Equality and Human Rights Commission (EHRC) is responsible for enforcing the Public Sector Bodies (Websites and Mobile Applications) (No. 2) Accessibility Regulations 2018 (the ‘accessibility regulations’). If you’re not happy with how we respond to your complaint please contact the Equality Advisory and Support Service (EASS) directly:<br /><br /><a href = 'https://www.equalityadvisoryservice.com/'>Contact details for the Equality Advisory and Support Service (EASS)</a><br /><br /> The government has produced information on how to report accessibility issues:<br /><br /><a href = 'https://www.gov.uk/reporting-accessibility-problem-public-sector-website'>Reporting an accessibility problem on a public sector website</a>
                </div>
                <div className = 'margin-top-2'></div>
                <IntroDescription text = 'Technical information about this website’s accessibility' />
                <div className = 'margin-top-2'></div>
                <div>
                    <b>Compliance status</b><br />
                    This website is fully compliant with the <a href = 'https://www.w3.org/TR/WCAG21/'>Web Content Accessibility Guidelines version 2.1 AA standard</a>.
                </div>
                <div className = 'margin-top-2'></div>
                <div>
                    <b>Disproportionate burden</b><br />
                    We are not currently claiming that any accessibility problems would be a disproportionate burden to fix.
                </div>
                <div className = 'margin-top-2'></div>
                <div>
                    <b>Content that’s not within the scope of the accessibility regulations</b><br />
                    At the time of writing, we are not aware of any content that is not out with the scope of the accessibility regulations.
                </div>
                <div className = 'margin-top-2'></div>
                <div>
                    <b>Preparation of this accessibility statement</b><br />
                    This statement was prepared on 11th March 2022. It was last reviewed on 20th March 2022.<br /><br />
                    This mobile application was last tested by using an Apple iPhone 6s and Android Galaxy A40. We tested using<br /><br />
                    <a href = 'https://wave.webaim.org/'>WAVE</a> by WebAIM<br /><br />
                    WAVE is a suite of tools for facilitating web accessibility evaluation by providing a visual representation of accessibility issues within the page. There is an online service at <a href = 'https://wave.webaim.org/'>https://wave.webaim.org/</a>, Firefox and Chrome browser extensions, an online API service, and a stand-alone API product.
                    <br /><br />
                    ** <a href = 'https://www.accessibilitychecker.org/'>Accessibility Checker</a> by Intent Based 
                    <br /><br />
                    A free web-based audit tool that scans your website for current WCAG standards. Its uniqueness is the presentation of the errors it finds on your website. For every error the scanner finds, you will receive a detailed explanation about it, whom it affects and multiple options on how to solve it.
                    <br /><br />
                    <a href = 'https://www.accessibilitychecker.org/'>https://www.accessibilitychecker.org/</a>, Version: 1, Released: 2021-Jun-20
                    <br /><br />
                    The checks were to identify if the site;
                    <br /><br />
                    meets level AA of the <a href = 'https://www.gov.uk/service-manual/helping-people-to-use-your-service/understanding-wcag'>Web Content Accessibility Guidelines (WCAG 2.1)</a>
                    <br /><br />
                    works on the most commonly used <a href = 'https://www.gov.uk/service-manual/technology/testing-with-assistive-technologies'>assistive technologies</a> – including screen magnifiers, screen readers and speech recognition tools
                </div>
                <div className = 'margin-top-5' />
            </div>

        </>
    );
}

export default AccessibilityStatement;
