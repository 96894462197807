import MenuButtonInactive from "../components/MenuButtonInactive";
import BackButton from "../components/BackButton";
import IntroHeading from "../components/IntroHeading";
import team_croucher from '../images/team_croucher.jpg'
import team_bryant_ellie from '../images/team_bryant_ellie.jpg'
import team_sutton from '../images/team_sutton.jpg'
import team_caroline_lloyd from '../images/team_caroline_lloyd.jpg'
import { useEffect } from 'react'

const Team = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
        }, []
    )
    
    return (
        <>
            <MenuButtonInactive />
            <BackButton />
            <div className = 'team-body'>
                <div className = 'padding-top-5' />
                <IntroHeading text = 'Meet the Team' />
                <div className = 'margin-top-5' />
                <div>
                    <p className = 'center'>
                        <span className = 'bold'>Name:</span> Dr Karina Croucher 
                        <br /> 
                        <span className = 'bold'>Role:</span> Project lead
                    </p>
                    <div className = 'margin-top-3' />
                    <div className = 'team-img-link-container'>
                        <div className = 'team-img'>
                            <img src = {team_croucher} alt = 'Dr. Karina Croucher' />
                        </div>
                        <div className = 'team-profile-link'>
                            <a href = 'https://www.linkedin.com/in/karina-croucher-8724405a' alt = 'Linkedin Profile Link'>Linkedin Profile</a>
                            <br />
                            <a href = 'https://twitter.com/karinaktc?lang=en' alt = 'Twitter Profile Link'>Twitter Profile</a>
                        </div>
                    </div>
                    <div className = 'margin-top-3' />
                    Karina is an Associate Professor in Archaeology at the University of Bradford, where she researches funerary archaeology. She leads on the Continuing Bonds project, which uses archaeology to prompt discussion and challenge biases about death and bereavement. She is a Senior Fellow of the Higher Education Academy, and her teaching and research both reflect her interest in the role of the past in the present.
                </div>

                <div className = 'margin-top-5' />

                <div>
                    <p className = 'center'>
                        <span className = 'bold'>Name:</span> Dr Eleanor Bryant
                        <br /> 
                        <span className = 'bold'>Role:</span> Co-Investigator
                    </p>
                    <div className = 'margin-top-3' />
                    <div className = 'team-img-link-container'>
                        <div className = 'team-img'>
                            <img src = {team_bryant_ellie} alt = 'Dr. Eleanor Bryant' />
                        </div>
                        <div className = 'team-profile-link'>
                            <a href = 'https://www.linkedin.com/in/dr-eleanor-bryant-887b8326/' alt = 'Linkedin Profile Link'>Linkedin Profile</a>
                            <br />
                            <a href = 'https://twitter.com/karinaktc?lang=en' alt = 'Twitter Profile Link'>Twitter Profile</a>
                        </div>
                    </div>
                    <div className = 'margin-top-3' />
                    Eleanor is an Associate Professor in Psychology, School of Social Sciences at the University of Bradford. As well as research interests surrounding eating behaviours and appetite in both children and adults, she collaborates nationally and internationally on research projects in this area. Eleanor has also led the development of a Masters programme in Psychology and she teaches a range of modules regarding eating behaviour,  health and advanced research methods. She is also a Fellow of the Higher Education Academy.
                </div>

                <div className = 'margin-top-5' />

                <div>
                    <p className = 'center'>
                        <span className = 'bold'>Name:</span> Aoife Sutton
                        <br /> 
                        <span className = 'bold'>Role:</span> Project Assistant and consultant
                    </p>
                    <div className = 'margin-top-3' />
                    <div className = 'team-img-link-container'>
                        <div className = 'team-img'>
                            <img src = {team_sutton} alt = 'Aoife Sutton' />
                        </div>
                        <div className = 'team-profile-link'>
                            <a href = 'https://www.linkedin.com/in/aoife-sutton-8249971b7' alt = 'Linkedin Profile Link'>Linkedin Profile</a>
                            <br />
                            <a href = 'https://twitter.com/pathbodies?lang=en' alt = 'Twitter Profile Link'>Twitter Profile</a>
                        </div>
                    </div>
                    <div className = 'margin-top-3' />
                    Aoife is a PhD researcher in the School of Archaeological and Forensic Sciences at the University of Bradford. Her research focuses on the ethics, display and obtainment of anatomical, fluid preserved human remains dating to the 18th and 19th centuries in Britain. She also writes a blog entitled ‘The Pathological Bodies Project’, and undertakes training in embalming practices and mortuary work.
                </div>

                <div className = 'margin-top-5' />

                <div>
                    <p className = 'center'>
                        <span className = 'bold'>Name:</span> Dr Jennie Dayes
                        <br /> 
                        <span className = 'bold'>Role:</span> Consultant
                    </p>
                    <div className = 'margin-top-3' />
                    Aoife is a PhD researcher in the School of Archaeological and Forensic Sciences at the University of Bradford. Her research focuses on the ethics, display and obtainment of anatomical, fluid preserved human remains dating to the 18th and 19th centuries in Britain. She also writes a blog entitled ‘The Pathological Bodies Project’, and undertakes training in embalming practices and mortuary work.
                </div>

                <div className = 'margin-top-5' />

                <div>
                    <p className = 'center'>
                        <span className = 'bold'>Name:</span> Caroline Lloyd
                        <br /> 
                        <span className = 'bold'>Role:</span> Consultant
                    </p>
                    <div className = 'margin-top-3' />
                    <div className = 'team-img-link-container'>
                        <div className = 'team-img'>
                            <img src = {team_caroline_lloyd} alt = 'Caroline Lloyd' />
                        </div>
                        <div className = 'team-profile-link'>
                            <a href = 'https://www.linkedin.com/in/caroline-lloyd-2324421a/' alt = 'Linkedin Profile Link'>Linkedin Profile</a>
                            <br />
                            <a href = 'https://twitter.com/DrLloydToBe' alt = 'Twitter Profile Link'>Twitter Profile</a>
                        </div>
                    </div>
                    <div className = 'margin-top-3' />
                    Caroline has been working and volunteering with the terminally ill and bereaved for many years. She creates and delivers training to professionals, is currently completing her PhD at Trinity College Dublin, and is author of Grief Demystified: An Introduction.  She can be found at: <a href = 'https://www.carolinelloyd.co.uk/' alt = 'caroline lloyd web link'>www.carolinelloyd.co.uk</a>
                </div>

                <div className = 'margin-top-5' />

                <div>
                    <p className = 'center'>
                        <span className = 'bold'>Name:</span> Martin Truelove
                        <br /> 
                        <span className = 'bold'>Role:</span> Consultant
                    </p>
                    <div className = 'margin-top-3' />
                    Martin volunteers for Cruse Bereavement Care, for whom he supervises & trains volunteers who provide bereavement support for those living in the Craven & Bradford districts of West Yorkshire. He provides telephone support for people across the UK who have suffered a loss during the lockdown for Covid.
                </div>

                <div className = 'margin-top-5' />

                <div>
                    <p className = 'center'>
                        <span className = 'bold'>Name:</span> Laurie Bryant
                        <br /> 
                        <span className = 'bold'>Role:</span> Consultant
                    </p>
                    <div className = 'margin-top-3' />
                    Laurie brings personal experience of bereavement, and works to support others in their bereavement. He has worked in government roles, to assess and improve the quality of and access to mental health services. Laurie has consulted both nationally and internationally on his expertise of mental health services  and improving the user experience of services. Within this role, he had a pivitol impact in creating the Increasing Access to Psychological Therapies (IAPT) services for the NHS, which is now implemented nationwide. Laurie also lectures at Hull and York Universities on the Social Work courses.
                </div>

                <div className = 'margin-top-5' />

                <div>
                    <p className = 'center'>
                        <span className = 'bold'>Name:</span> Mia Coe
                        <br /> 
                        <span className = 'bold'>Role:</span> Placement Student
                    </p>
                    <div className = 'margin-top-3' />
                    Mia is a placement student on the Continuing Bonds and Dying to Talk projects. She is studying archaeology at the University of Bradford.
                </div>

                <div className = 'margin-top-5' />

                <div>
                    This toolkit has been made possible by the original Continuing Bonds project team: Dr Karina Croucher, Prof Christina Faull, Dr Laura Green, Dr Lindsey Buster, Dr Jennifer Dayes, and Dr Justine Rainsford. Their work on the project, along with the volunteer participants, has provided content and experience that has made the toolkit possible.
                </div>
            </div>
        </>
    )
}

export default Team;
