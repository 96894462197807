import ReactPlayer from 'react-player'
import classes from './VideoPlayer.module.css'

const VideoPlayer = (props) => {
    return (
        <div className = {classes.container}>
            <ReactPlayer
                className = {classes.player} 
                url={props.url} controls={true}
                width = '100%'
                height = '100%'
                config={{ youtube: { playerVars: { origin: 'https://www.youtube.com' } } }}
            />
        </div>
    )
}

export default VideoPlayer;