import MenuButtonInactive from "../components/MenuButtonInactive";
import BackButton from "../components/BackButton";
import IntroHeading from "../components/IntroHeading";
import IntroDescription from "../components/IntroDescription";
import { useEffect } from 'react'

const GroundRules = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
        }, []
    )
    
    return (
        <>
            <MenuButtonInactive />
            <BackButton />
            <div className = 'intro-div-surrounding'>
                <div className = 'padding-top-5' />
                <IntroHeading text = 'Ground Rules' />
                <div className = 'margin-top-5' />
                <IntroDescription text = 'When using the toolkit, we advise the following:' />
                <div className = 'margin-top-3' />
                <ul>
                    <li>Ensure you are in a safe, supportive environment, without distractions (this will be dependent on the individual, and may be a quite private space, or a space with a counsellor or relative/friend)</li>
                    <br />
                    <li>When using in a workshop environment, confidentiality must be ensured</li>
                    <br />
                    <li>When using in a workshop environment, have respect for all, allowing different voices to be heard, in a non-judgemental manner. Please encourage participants to speak up, and enable others to do the same.</li>
                    <br />
                    <li>If there is a reason for recording the session, agree this beforehand (including how the recording will be stored and used).</li>
                    <br />
                    <li>Ensure users know where to seek additional sources of support (See the Support section in the menu for suggestions)</li>
                    <br />
                    <li>Take care of yourself - take a break if you need to, cry if you need to, suppress telling difficult stories if you prefer to - as an individual, you are in charge. The ‘self-care’ section of the toolkit applies to all content.</li>
                    <br />
                    <li>Try to make the most of the safe space to share and talk about an often-difficult topic - this can be liberating, informative, and a real learning journey. </li>
                    <br />
                    <li>Remember that there is not a ‘one size fits all’ approach to bereavement and loss - everyone is individual and while grieving styles and types are given, they rarely transpose directly on to individuals. Try not to put yourself or others into boxes, but rather recognise the diverse individual experiences that may draw on one or several grieving approaches.</li>
                </ul>
                <div className = 'margin-top-2' />
            </div>
        </>
    )
}

export default GroundRules;
