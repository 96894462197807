import { Link } from 'react-router-dom'
import Card from "../components/Card"
import BackButton from '../components/BackButton'
import MenuButtonInactive from '../components/MenuButtonInactive'
import IntroHeading from '../components/IntroHeading'
import axios from 'axios'
import { useEffect, useState } from 'react'
import PropagateLoader from "react-spinners/PropagateLoader";

const Modules = () => {

    const [modules, setModules] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getModulesFromApi();
    }, [])

    const getModulesFromApi = async () => {

        const config = {
            headers: {
                "Authorization": "Token " + localStorage.getItem('token')
            }
        }

        const response = await axios.get('/api/modules/', config);
        setModules(response.data)
        setLoading(false)
    }

    return (
        <>
        <BackButton />
        <MenuButtonInactive />
        {
        loading ? <PropagateLoader color={'#ffffff'} loading={loading} size={15} />
        :
        <>
            <div className = 'margin-top-20'></div>
            <IntroHeading text = 'Modules' />
            <div className = 'center-vertical center'>
                {
                    modules.map((module) => <div key = {module.id}><Link to = {{pathname: `/modules/${module.id}`, state: module}}><Card text = {module.heading} /></Link><div className = 'margin-top-1-5' /></div>)
                }
            </div>
        </>
        }
        </>
    )

}

export default Modules;
