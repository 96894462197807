import MenuButtonInactive from "../components/MenuButtonInactive";
import BackButton from "../components/BackButton";
import IntroHeading from "../components/IntroHeading";
import IntroSubHeading from "../components/IntroSubHeading";
import { useEffect } from 'react'

const ContactUs = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
        }, []
    )
    
    return (
        <>
            <MenuButtonInactive />
            <BackButton />
            <div className = 'intro-div-surrounding'>
                <div className = 'padding-top-5' />
                <IntroHeading text = 'Contact Us' />
                <div className = 'margin-top-5' />
                <div >
                    We are dedicated to ensuring the Continuing Bonds Toolkit offers the best possible experience. We would love to hear from our users so please send us a message, we are only a small team and will try our best to get back to you within 5 working days.
                    <div className = 'margin-top-3' />
                    Tell us what you think, anything you think is missing from the Toolkit, what you think works well and what we could be doing better. We are always looking to improve the Toolkit to offer you the best experience. Thank you.
                    <div className = 'margin-top-3' />

                    <IntroSubHeading text = 'Email:' />
                    <a href = 'mailto:continuingbonds@bradford.ac.uk'>continuingbonds@bradford.ac.uk</a>
                    
                    <div className = 'margin-top-3' />
                    <IntroSubHeading text = 'Or write to:' />
                    <address>
                        Continuing Bonds, c/o Dr Karina Croucher
                        <br />
                        School of Archaeological and Forensic Sciences
                        <br />
                        Richmond Building
                        <br />
                        University of Bradford
                        <br />
                        Bradford BD18 3AZ
                        <br />
                        United Kingdom
                    </address>
                </div>
                <div className = 'margin-top-2' />
            </div>
        </>
    )
}

export default ContactUs;
