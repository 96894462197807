import MenuButtonInactive from "../components/MenuButtonInactive";
import BackButton from "../components/BackButton";
import IntroHeading from "../components/IntroHeading";

const FurtherReadingItem = (props) => {

    // props.location.state.pdf_link = 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf'


    const url = `https://docs.google.com/viewer?url=${props.location.state.pdf_link}&embedded=true`
    // const url2 = 'https://www.youtube.com/'
    return (
        <>
            <MenuButtonInactive />
            <BackButton />
            <div className = 'intro-div-surrounding'>
            <div className = 'padding-top-5' />
                <IntroHeading text = {props.location.state.heading} />
                <div className = 'margin-top-5' />
                <div className = 'center'>
                    <iframe src={url} width="100%" height="500" title = {props.location.state.heading}></iframe>
                </div>

            </div>
        
        </>
    )
}

export default FurtherReadingItem