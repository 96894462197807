import classes from './IconFullscreen.module.css'

const IconFullscreen = (props) => {

    return (
        <span className = {`fa-stack fa-2x ${classes.stack_style}`} onClick = {props.showFullscreen}>
            <i className = "fa fa-circle fa-stack-2x icon-background"></i>
            <i className = {`fa fa-arrows fa-stack-1x ${classes.inactive}`}></i>
        </span>

    );
  }
  
 export default IconFullscreen;
