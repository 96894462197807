import { Link } from 'react-router-dom';
import BackButton from '../components/BackButton';
import MenuButtonInactive from '../components/MenuButtonInactive';
import Card from "../components/Card";

const PersonalStories = (props) => {

    const personalStoryData = props.location.state
    
    return (
        <>
        <BackButton />
        <MenuButtonInactive />
        <div className = 'big-screen-top-margin' />
        <div className = 'center-vertical center'>
            {
                personalStoryData.map((personalStory) => <div key = {personalStory.id}><Link to = {{pathname: '/personalStory', state: personalStory}}><Card text = {personalStory.heading} /></Link><div className = 'margin-top-1-5' /></div>)
            }
        </div>
        </>
    )

}

export default PersonalStories;