import Header from "../components/Header";
import { useRef, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import Error from "../components/Error";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import PropagateLoader from "react-spinners/PropagateLoader";
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';

export default function Verification() {
  const codeRef = useRef();
  const { login } = useAuth();
  const [err, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showResend, setShowResend] = useState(true);
  const {data} = useVisitorData();
  const history = useHistory();
  const username = localStorage.getItem('email');

  const resetError = () => {
    setError("");
    console.log("reset error");
  };

  const handleSubmit = async (e) => {
      
    e.preventDefault()

    setLoading(true)

    const config = {
      headers: {
          "Content-Type": "application/json"
      }
    }

    const code = codeRef.current.value
    const browserFingerprint = data.visitorId;
    const body = JSON.stringify({username, code, "browser_fingerprint" : browserFingerprint});

    axios.put('/api/auth/verify', body, config).then((res) => {

      if (res.data.is_email_confirmed) {
        login(username, browserFingerprint).then((res) => {
          localStorage.setItem("token", res.data.token);
          history.push("/introduction")
          // setLoading(false)
        })
        
      } else {
        setError('The code entered is not matched, Please enter the correct code')
        setLoading(false)
      }
    }).catch((err) => {
      setError('Please enter the verification code and try again')
      setLoading(false)
    })
    
  }

  const resendCode = () => {

    setLoading(true)
    setShowResend(false)
    const config = {
      headers: {
          "Content-Type": "application/json"
      }
    }

    axios.get('/api/auth/resend_verification?email=' + username, config).then(res => {
      if (res.data.message === 'success')
        setShowResend(false)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
    })
  }

  return (
    <>
      <Header />
      {
      loading ? <PropagateLoader color={'#ffffff'} loading={loading} size={15} />
      :
      <div className="center auth-surrounding">
        <div className="padding-top-5"></div>
        <div className="toolkit-title">Continuing Bonds Toolkit</div>
        <div className="margin-top-10"></div>
        <div className="verify-code-label">Please enter the verification code sent to your email. If you did not receive check your junk folder or request another below</div>
 
        <div className="margin-top-10"></div>
        <input
          type="number"
          placeholder="Verification Code"
          ref={codeRef}
          aria-label="Verification Code"
          id="verification_code"
          onMouseDown={resetError}
        />
        {err && (
          <div className="err">
            <div className="margin-top-2" />
            <Error header="Login Error" content={err} />
          </div>
        )}
        <div className="margin-top-10"></div>
        <div className={err ? "hide-elm" : ""}>
          <button disabled={loading} type="submit" onClick={handleSubmit}>
            Verify
          </button>
        </div>
        <div className="margin-top-10"></div>
        {showResend ? 
          <>
            <p>Did you miss the activation email?</p>
            <p className = 'pointer' onClick={resendCode}>Email Me Activation Code Again</p>

          </>
          :
          <>
            <p>We have sent the verification email to {username}</p>
          </>
        }
        
      </div>
    }
    </>
  )
}
