import "./ModalCaseStudy.css";
// import { useHistory } from "react-router-dom";
import axios from 'axios';

const ModalCaseStudy = ({ handleClose, show, checkedStatus, children }) => {

  const showHideClassName = show ? "modal display-block" : "modal display-none"
  // const history = useHistory()

  const navigate = () => {
    // if the user checked the tick box then need to set false for show_casestudy_popup
    if (checkedStatus) {
      localStorage.setItem('show_casestudy_popup', !checkedStatus)
    }

    updateDB()

    handleClose()
  }

  const updateDB = () => {

    const config = {
      headers: {
          "Content-Type": "application/json"
      }
    }

    const username = localStorage.getItem('email')
    const body = JSON.stringify({username})

    axios.put('/api/auth/case_study_popup', body, config).then((res) => {
      console.log(res.data) 
    }).catch((err) => {
      console.log(err)
    })
  }

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        {children}
        <div className="modal-button">
          <button type="button" onClick={navigate}>Proceed</button>
        </div>

      </section>
    </div>
  )
}

export default ModalCaseStudy
