import classes from './Error.module.css'

const Error = (props) => {
    return (
        <div className={classes.error}>
            <div className={classes.header} >{props.header}</div>
            <div className = 'margin-top-1'></div>
            <div className={classes.content} >{props.content}</div>
        </div>
    )
}

export default Error