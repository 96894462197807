import classes from "./MenuButtonInactive.module.css";
import { Link } from "react-router-dom";

const MenuButtonInactive = () => {

  const classContainer = classes.container + ' home-button'

  return (
    <Link to="/">
      <div className={classContainer}>
        <i className="fa fa-home menu-inactive-icon" aria-hidden="true" />
      </div>
    </Link>
  );
};

export default MenuButtonInactive;
