import BackButton from '../components/BackButton';
import MenuButtonInactive from '../components/MenuButtonInactive'
import IntroHeading from "../components/IntroHeading";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
// import IntroDescription from "../components/IntroDescription";

const Learning = (props) => {

    const learningDataProps = props.location.state

    // console.log()
    const { id } = useParams();
    console.log(id);
    
    const [learningData, setLearningData] = useState([]);

    useEffect(() => {
        if (learningDataProps) {
            setLearningData(learningDataProps);
            console.log('fetching learning data');
        } else {
            const getLearningData = async () => {
                const config = {
                    headers: {
                        "Authorization": "Token " + localStorage.getItem('token')
                    }
                }
    
                const response = await axios.get(`/api/modules/${id}/`, config);
                setLearningData(response.data);
            };
            getLearningData();
        }
    }, [id, learningDataProps])



    // console.log(learningData.heading)

    
    return (
        <>
        <BackButton />
        <MenuButtonInactive />
        <div className = 'intro-div-surrounding'>
            <div className = 'padding-top-5' />
            <IntroHeading text = {learningData.heading} />
            <div className = 'padding-top-5' />
            {/* <IntroDescription text = {learningData.heading} /> */}
            <div dangerouslySetInnerHTML={{
                    __html: learningData.content
                  }}></div>
            
        </div>
        </>
    )

}

export default Learning;
