import classes from './IconQuestion.module.css'

const IconQuestion = (props) => {


    const questionStyle = `fa fa-question fa-stack-1x ${classes.inactive}`
    const stack_style = `fa-stack fa-2x ${classes.stack_style}`


    return (
        <span className = {stack_style} onClick = {props.showQuestion}>
            <i className = "fa fa-circle fa-stack-2x icon-background"></i>
            <i className = {questionStyle}></i>
        </span>

    );
  }
  
  export default IconQuestion;
