import BackButton from "../components/BackButton";
import MenuButtonInactive from "../components/MenuButtonInactive";
import IntroHeading from "../components/IntroHeading";
import IntroDescription from "../components/IntroDescription";
import AboutLogo from "../components/AboutLogo";
import bradfordLogo from '../images/about_bradford_logo.svg';
import ukRiLogo from '../images/about_ukri_logo.png';
import lorosLogo from '../images/about_loros_logo.png'
import { useEffect } from 'react'


const About = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
        }, []
    )
    
    return (
        <>
            <MenuButtonInactive />
            <BackButton />
            <div className = 'intro-div-surrounding'>
                <div className = 'padding-top-5' />
                <IntroHeading text = 'About' />
                <IntroHeading text = 'Continuing Bonds' />
                <div className = 'margin-top-5' />
                <IntroDescription text = 'Welcome to the Continuing Bonds Toolkit: Using archaeology in grief and bereavement counselling.' />
                <div className = 'margin-top-3' />
                <div>
                    While death is universal, human reactions to death can be diverse. These reactions are influenced by the societies we live in, the types of personal experiences we have had, and the ways we deal with life events, including loss. Yet, despite the universality of death, it can be a difficult subject for many to talk about. An avoidance of the normality of death may result in individuals struggling with experiences related either directly or indirectly to death, grief and/or bereavement.
                </div>
                <div className = 'margin-top-2'></div>
                <div> 
                    The toolkit is a result of research into the use of archaeology for normalising talk about death, dying and bereavement. The original project, ‘Continuing Bonds: exploring the meaning and legacy of death through past and contemporary practice’ (2016-18) was a two-year collaboration between archaeology and healthcare at the University of Bradford and LOROS Hospice, Leicester, funded by the Arts and Humanities Research Council (AHRC). The project team found that providing a safe space, using materials which provided cognitive dissonance (that challenge what we may think of as ‘normal’ and take us outside of our comfort zone), enabled discussion, and encouraged participants (who were health and social care professions and students) to think differently about death and bereavement, as well as normalising and validating diverse experiences of bereavement. 
                </div>
                <div className = 'margin-top-2'></div>
                <div>
                    The theme of Continuing Bonds is the understanding that for many, death is not the end of relationships between the living and the dead, and that the dead continue to have meaning and significance in the lives of the living. The theory was developed by Klass, Silverman and Merriman (1996), and is summed up nicely in this quote from Litsa Williams, What’s your grief? (2014):
                </div>
                <div className = 'margin-top-2'></div>
                <div className = 'italic'>
                    “When your loved one dies grief isn’t about working through a linear process that ends with ‘acceptance’ or a ‘new life’, where you have moved on or compartmentalized your loved one’s memory. Rather, when a loved one dies you slowly find ways to adjust and redefine your relationship with that person, allowing for a continued bond with that person that will endure, in different ways and to varying degrees, throughout your life”
                </div>
                <div className = 'margin-top-2'></div>
                <div>
                    The toolkit presents an innovative resource. It uses history, archaeology (examples from the past) and ethnography (diverse experiences from around the world) to normalise talking about death, dying and bereavement. Through exploring the past, we can see the vast variety of ways that people have dealt with death, dying and loss across time and place. The toolkit materials provide a ‘safe’ way in to opening these topics, because the archaeological and ethnographic examples are fascinating, yet crucially, are removed from our modern daily experiences. This historical ‘distancing’ enables a perspective which helps break the ice, prompting discussion, and reflection, on contemporary, professional, and personal experiences. 
                </div>
                <div className = 'margin-top-2' />
                <IntroDescription text = 'For more, visit our website:' />
                <div>
                    <a href = 'https://continuingbonds.live/'>https://continuingbonds.live</a>, and read our findings here
                </div>
                <div className = 'margin-top-5' />
                <div className = 'about-logo'>
                    <AboutLogo src={bradfordLogo} alt = 'bradford university logo' />
                    <div className = 'margin-top-2' />
                    <AboutLogo src={ukRiLogo} alt = 'UK RI logo' />
                    <div className = 'margin-top-2' />
                    <AboutLogo src={lorosLogo} alt = 'Loros logo' />
                </div>
                <div className = 'margin-top-5' />
                <div className = 'rd-caption'>
                    Developed by <a href = 'https://runedigital.com/'>Rune Digital</a>
                </div>
                
            </div>

        </>
    );
}

export default About;
