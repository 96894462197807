import { Link } from 'react-router-dom'
import CardCaseStudy from '../components/CardCaseStudy'
import Tag from '../components/Tag'
import BackButton from '../components/BackButton'
import MenuButtonInactive from '../components/MenuButtonInactive'
import IntroHeading from '../components/IntroHeading'
import Card from '../components/Card'
import axios from 'axios';
import { useState } from 'react'
import PropagateLoader from "react-spinners/PropagateLoader";
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { useQuery } from 'react-query';


/*
get case studies data from the API
get case study filters from the API

show all the case studies if no filters applied
if filters applied show only the case studies that match the filters
*/

let filters_focus = []
let filters_bereavement = []

const CaseStudies = () => {

    const [showFilter, setShowFilter] = useState(false);
    const [showFilterDiv, setShowFilterDiv] = useState('');
    const [filteredCaseStudies, setFilteredCaseStudies] = useState([])

    const config = {
        headers: {
            "Authorization": "Token " + localStorage.getItem('token')
        }
    };

    const caseStudies = useQuery('caseStudies', async () => {
        const response = await axios.get('/api/caseStudies/', config);
        return response.data;
    });

    const caseStudyFilters = useQuery('caseStudyFilters', async () => {
        const response = await axios.get('/api/caseStudyFilters/', config);
        return response.data;
    });

    const filterClickHandler = () => {
        setShowFilter(!showFilter)
        // setFilteredCaseStudies([])
        filters_focus = []
        filters_bereavement = []
    }

    const hideFilterDiv = () => { 
        if (showFilterDiv === 'hide-shrink-elm') {
            setShowFilterDiv('');
        } else {
            setShowFilterDiv('hide-shrink-elm');
        }
    }

    const clickHandler = (text, style, isAdd) => {

        if (isAdd) {
            if (style === 'focus') {
                filters_focus.push(text)
            } else {
                filters_bereavement.push(text)
            }
        } else {
            if (style === 'focus') {
                filters_focus.indexOf(text) !== -1 && filters_focus.splice(filters_focus.indexOf(text), 1)
            } else {
                filters_bereavement.indexOf(text) !== -1 && filters_bereavement.splice(filters_bereavement.indexOf(text), 1)
            }
        }
        let obj = []
        for (let i = 0; i < caseStudies.data.length; i++) {

            for (let j = 0; j < filters_bereavement.length; j++) 
                for (let filter of caseStudies.data[i].case_study_filter) 
                    if (filter.style !== 'focus')
                        if (filter.tag === filters_bereavement[j]) 
                            obj.push(caseStudies.data[i])
            
            if (filters_bereavement.length === 0) {
                console.log('no bereavement filters')
                for (let j = 0; j < filters_focus.length; j++)
                    for (let filter of caseStudies.data[i].case_study_filter)
                        if (filter.style === 'focus')
                            if (filter.tag === filters_focus[j]) 
                                obj.push(caseStudies.data[i])   
            }
  
        }
        if (filters_bereavement.length > 0 && filters_focus.length > 0) {
            const bothFIltersObj = []
            obj.forEach(caseStudy => {
                filters_focus.forEach(filter => {
                    if (caseStudy.case_study_filter.find(f => f.tag === filter && f.style === 'focus')) {
                        bothFIltersObj.push(caseStudy)
                    }
                })
            })
            obj = bothFIltersObj

        }

        console.log(obj)
        obj = obj.filter((v,i,a)=>a.findIndex(t=>(t.id===v.id))===i)
        console.log(obj)
        setFilteredCaseStudies(obj)

    }

    if (caseStudies.isLoading) {
        return (
            <div className="loading">
                <PropagateLoader color={'#ffffff'} size={15} />
            </div>
        );
    }

    if (caseStudies.isSuccess) {
        return (
            <>
                <BackButton />
                <MenuButtonInactive />
                <div className = 'intro-div-surrounding'>
                    <div className = 'padding-top-5' />
                    <IntroHeading text = 'Case Studies' />
                    <div className = 'padding-top-5' />
                    <div className = 'case-study-filter-icon'>{showFilter ? <>{showFilterDiv === '' ? <i className="fa fa-eye-slash" aria-hidden="true" onClick={hideFilterDiv}></i> : <i className="fa fa-eye" aria-hidden="true" onClick={hideFilterDiv}></i>} | <i className="fa fa-remove" aria-hidden="true" onClick={filterClickHandler}></i></> : <i className="fa fa-sliders" aria-hidden="true" onClick={filterClickHandler}></i>}</div>
                    <div className = 'margin-top-3' />
                    { // show filters if the showFilter is true. according to the case study style filter will shown in two categories.
                        showFilter && 
                        <div className = {showFilterDiv}>
                            <fieldset className = 'case-study-filter-div'>
                            {/* 
                            loop through the case study filters and check in which category the filter is belongs to
                            if it belongs to focus show it under focus category else show it under bereavement category
                            */}
                            <legend>Bereavement Style:</legend>
                                {
                                    caseStudyFilters.data.filter(caseStudyFilter => caseStudyFilter.style === 'bereavement').map((filter) => <div key = {filter.id}><Tag text = {filter.tag} style = {filter.style} onClick = {clickHandler}></Tag></div>)
                                }
                            </fieldset>
        
                            <div className = 'margin-top-3' />
                            
                            <fieldset className = 'case-study-filter-div'>
                            <legend>Focus:</legend>
                                {
                                    caseStudyFilters.data.filter(caseStudyFilter => caseStudyFilter.style === 'focus').map((filter) => <div key = {filter.id}><Tag text = {filter.tag} style = {filter.style} onClick = {clickHandler}></Tag></div>)
                                }
                            </fieldset>
                            
                            <div className = 'padding-top-5' />
                        </div>
                    }
                    
                    {/* 
                    if any filters are applied show only the case studies that match the filters otherwise show all the case studies
                    */}
                    <div className = 'center'>
                        {
                            filteredCaseStudies.length > 0 && filteredCaseStudies.map((caseStudy) => <div key = {caseStudy.id}><Link to = {{pathname: `/caseStudy/${caseStudy.id}`, state: caseStudies.data}}><CardCaseStudy data = {caseStudy} /></Link><div className = 'margin-top-1-5' /></div>)
                        }
                        {
                            (filters_focus.length === 0 && filters_bereavement.length === 0) ? 
                            caseStudies.data.map((caseStudy) => {
                                return <div key = {caseStudy.id}><Link to = {{pathname: `/caseStudy/${caseStudy.id}`, state: caseStudies.data}}><CardCaseStudy data = {caseStudy} /></Link><div className = 'margin-top-1-5' /></div>
                            })
                            :
                            filteredCaseStudies.length === 0 && <Card text = {'No data for this filter'} />
                        }
                    </div>
                </div>
            
            </>


        );
    }

    return (
        <>
            <BackButton />
            <MenuButtonInactive />
        </>
    );

    // const [caseStudies, setCaseStudies] = useState([])
    // const [caseStudyFilters, setCaseStudyFilters] = useState([])
    // const [filteredCaseStudies, setFilteredCaseStudies] = useState([])
    // const [showFilter, setShowFilter] = useState(false)
    // const [showFilterDiv, setShowFilterDiv] = useState('')
    // // const [loading, setLoading] = useState(true)



    // const filterClickHandler = () => {
    //     setShowFilter(!showFilter)
    //     setFilteredCaseStudies([])
    //     filters_focus = []
    //     filters_bereavement = []
    // }

    // const hideFilterDiv = () => { 
    //     if (showFilterDiv === 'hide-shrink-elm') {
    //         setShowFilterDiv('')
    //     } else {
    //         setShowFilterDiv('hide-shrink-elm')
    //     }
    // }

    // if(csData.status === 'loading') {
    //     return (
    //         <>
    //             <BackButton />
    //             <MenuButtonInactive />
    //             <PropagateLoader color={'#ffffff'} size={15} />
    //         </>
    //     );
    // }
    // if(csData.status === 'error') {
    //     return <div>Error</div>
    // }
    // if(status === 'success') {
    //     return (
    //         <>
    //         <BackButton />
    //         <MenuButtonInactive />

    //         <div className = 'intro-div-surrounding'>
    //             <div className = 'padding-top-5' />
    //             <IntroHeading text = 'Case Studies' />
    //             <div className = 'padding-top-5' />
    //             <div className = 'case-study-filter-icon'>{showFilter ? <>{showFilterDiv === '' ? <i className="fa fa-eye-slash" aria-hidden="true" onClick={hideFilterDiv}></i> : <i className="fa fa-eye" aria-hidden="true" onClick={hideFilterDiv}></i>} | <i className="fa fa-remove" aria-hidden="true" onClick={filterClickHandler}></i></> : <i className="fa fa-sliders" aria-hidden="true" onClick={filterClickHandler}></i>}</div>
    //             {/* <FontAwesomeIcon icon="fas fa-toggle-on" /> */}
    //             <div className = 'margin-top-3' />
                // {
                //     showFilter && 
                //     <div className = {showFilterDiv}>
                //         <fieldset className = 'case-study-filter-div'>
                //         <legend>Bereavement Style:</legend>
                //             {
                //                 caseStudyFilters.filter(caseStudy => caseStudy.style === 'bereavement').map((filter) => <div key = {filter.id}><Tag text = {filter.tag} style = {filter.style} onClick = {clickHandler}></Tag></div>)
                //             }
                //         </fieldset>
    
                //         <div className = 'margin-top-3' />
                        
                //         <fieldset className = 'case-study-filter-div'>
                //         <legend>Focus:</legend>
                //             {
                //                 caseStudyFilters.filter(caseStudy => caseStudy.style === 'focus').map((filter) => <div key = {filter.id}><Tag text = {filter.tag} style = {filter.style} onClick = {clickHandler}></Tag></div>)
                //             }
                //         </fieldset>
                        
                //         <div className = 'padding-top-5' />
                //     </div>
                // }
    
    //             <div className = 'center'>
    //                 {
    //                     filteredCaseStudies.length > 0 && filteredCaseStudies.map((caseStudy) => <div key = {caseStudy.id}><Link to = {{pathname: `/caseStudy/${caseStudy.id}`, state: cs.data}}><CardCaseStudy data = {caseStudy} /></Link><div className = 'margin-top-1-5' /></div>)
                        
    //                 }

    //                 {
    //                     (filters_focus.length === 0 && filters_bereavement.length === 0) ? 
    //                     cs.data.map((caseStudy) => <div key = {caseStudy.id}><Link to = {{pathname: `/caseStudy/${caseStudy.id}`, state: cs.data}}><CardCaseStudy data = {caseStudy} /></Link><div className = 'margin-top-1-5' /></div>) : 
    //                         filteredCaseStudies.length === 0 && <Card text = {'No data for this filter'} />
    //                 }
    //             </div>
    //         </div>
    //         </>
    //     )
    // }

}

export default CaseStudies;
