import BackButton from "../components/BackButton";
import MenuButtonInactive from "../components/MenuButtonInactive";
import IntroHeading from "../components/IntroHeading";
import { useEffect } from 'react'

const PrivacyStatement = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
        }, []
    )
    
    return (
        <>
            <MenuButtonInactive />
            <BackButton />
            <div className = 'intro-div-surrounding'>
                <div className = 'padding-top-5' />
                <IntroHeading text = 'Privacy Statement' />
                <div className = 'margin-top-5' />
                <div>
                    <a href = 'https://cb-toolkit.bradford.ac.uk'>https://cb-toolkit.bradford.ac.uk</a> ‘Continuing Bonds Toolkit’ is managed by the University of Bradford. The site is owned by the University of Bradford. The University of Bradford is the data controller for pages starting with www.cb-toolkit/
                </div>
                <div className = 'margin-top-2'></div>
                <div> 
                    If you follow a link to another organisations site or service link from www.cb-toolkit/ pages, that organisation will:
                </div>
                <ul>
                    <li>be the data controller</li>
                    <li>be responsible for processing any data you share with them</li>
                    <li>publish and manage their own privacy notice with details of how to contact them</li>
                </ul>
                <div className = 'margin-top-2'></div>
                <div>
                    Continuing Bonds Toolkit servers collect the following analytics: 
                </div>
                <ul>
                    <li>web pages requested</li>
                    <li>referring web page</li>
                    <li>date and time</li>
                </ul>
                <div className = 'margin-top-2'></div>
                <div>
                    Continuing Bonds Toolkit does not associate this data to individual user identities.
                </div>
                <div className = 'margin-top-2'></div>
                <div>
                    Google Analytics processes anonymised information about:
                </div>
                <ul>
                    <li>the pages you visit on UoB/Well</li>
                    <li>how long you spend on UoB/Well page</li>
                    <li>how you got to the site</li>
                    <li>what you click on while you’re visiting the site</li>
                </ul>
                <div className = 'margin-top-2' />
                <div>
                    We do not store your personal information through Google Analytics (for example your name or address). We will not identify you through analytics information, and we will not combine analytics information with other data sets in a way that would identify who you are. We continuously test and monitor our data protection controls to make sure they’re effective and to detect any weaknesses.
                </div>
                <div className = 'margin-top-2' />
                <div>
                    <b>How we use the data</b> Data is collected through Google Analytics to see how you use the Continuing Bonds Toolkit site and see how well the site performs on your device. We do this to help:
                </div>
                <ul>
                    <li>make sure Continuing Bonds Toolkit is meeting the needs of its users</li>
                    <li>make improvements, for example, improving site search</li>
                    <li>make performance improvements, for example improving page load time and data usage</li>
                </ul>
                <div className = 'margin-top-2' />
                <div>
                    <b>Use of collected information:</b> Continuing Bonds Toolkit will only use personal information collected from this site for the purpose of communication back to individuals who contact us via the site.
                </div>
                <ul>
                    <li>Continuing Bonds Toolkit uses browser-IP-address information and anonymous-browser history to report information about site accesses and for profiling purposes. This information is used to improve Web presentation and utilisation. IP address information may also be used for troubleshooting purposes.</li>
                </ul>
                <div className = 'margin-top-2' />
                <div>
                    <b>Distribution of collected information:</b> Continuing Bonds Toolkit will not disclose, without your consent, personal information collected about you, except for certain explicit circumstances in which disclosure is required by law.
                </div>
                <ul>
                    <li>Continuing Bonds Toolkit will not distribute or sell personal information to third-party organisations.</li>
                </ul>
                <div className = 'margin-top-2' />
                <div>
                    This Privacy Statement does not apply to other University of Bradford web sites. You should review the privacy statement posted on other University of Bradford sites when you visit them.
                </div>
                <div className = 'margin-top-2' />
                <div>
                    Privacy Statement Revisions: This Privacy Statement was last revised on 11th March 2022. We may change this Privacy Statement at any time and for any reason. We encourage you to review this Privacy Statement each time you visit the web site.
                </div>
                <div className = 'margin-top-2' />
                <div>
                    Responsibility for External Sites:This website contains links to other websites. Some of those web sites may be operated by third parties. We provide the links for your convenience, but we do not  review, control, or monitor the privacy practices of web sites operated by others.
                </div>
                <div className = 'margin-top-2' />
                <div>
                    We are not responsible for the performance of web sites operated by third parties or for your business dealings with them. Therefore, whenever you leave this web site we recommend that you review each web site’s privacy practices and make your own conclusions regarding the adequacy of these practices.
                </div>
                <div className = 'margin-top-2' />
                <div>
                    While using this website, you may encounter hypertext links to the Web pages of third-party websites we do control the content or information practices of external organisations.  We recommend you review the privacy statements of these organisations.
                </div>
            </div>

        </>
    );
}

export default PrivacyStatement;
