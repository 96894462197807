import { Link } from 'react-router-dom'
import Card from "../components/Card";
import BackButton from '../components/BackButton';
import MenuButtonInactive from '../components/MenuButtonInactive';
import IntroHeading from '../components/IntroHeading';
import axios from 'axios';
import { useEffect, useState } from 'react';
import PropagateLoader from "react-spinners/PropagateLoader";

const MovingForward = () => {

    const [researches, setResearches] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getResearches();
    }, [])

    const getResearches = async () => {
        const config = {
            headers: {
                "Authorization": "Token " + localStorage.getItem('token')
            }
        }

        const response = await axios.get('/api/movingForwards/', config);
        setResearches(response.data)
        setLoading(false)
    };

    return (
        <>
            <BackButton />
            <MenuButtonInactive />
            {
            loading ? <PropagateLoader color={'#ffffff'} loading={loading} size={15} />
            :
            <>
                <div className = 'margin-top-15' />
                <div className = 'padding-top-5' />
                <IntroHeading text = 'Moving Forward' />
                <div className = 'padding-top-5' />
                <div className = 'margin-both-side'>
                    All individuals grieve differently, learn differently and experience differently. Therefore, as a therapist or grief worker, it is important to have a variety of ‘tools’ in your toolbox, as depicted below. We suggest some tools, tasks, or ‘homework’ for those completing the modules and for clients.  Generally, when we suggest ‘homework’, or as we prefer ‘personal research’, the purpose is to help individuals address and discuss their grief and their life. In the grieving journey, moving forward from point A to a point B is often not clear, not linear and can be distressing, confusing, fast or slow, all of these are normal. In addition, the point B is often vague, undefined and open, and this is also fine. We want to encourage clients to explore an aspect of their grief, learn something new (whatever this is) and move forward (whatever this means) in some way.
                </div>
                <div className = 'padding-top-5' />
                <div className = 'center'>
                    {
                        researches.map((research) => <div key = {research.id}><Link to = {{pathname: `/personalResearch/${research.id}`, state: researches}}><Card text = {research.short_title} /></Link><div className = 'margin-top-1-5' /></div>)
                    }
                </div>
                <div className = 'padding-top-5' />
            </>
            }
        </>
    );
}

export default MovingForward;
