import { Link } from 'react-router-dom'
import Card from '../components/Card';
import { useEffect, useState } from 'react';
import axios from 'axios';
import MenuButtonInactive from "../components/MenuButtonInactive";
import BackButton from "../components/BackButton";
import IntroHeading from "../components/IntroHeading";
import PropagateLoader from "react-spinners/PropagateLoader";

const FurtherReading = (props) => {

    const [furtherReadings, setFurtherReading] = useState([]);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getFurtherReadingsFromApi();
    }, [])

    const getFurtherReadingsFromApi = async () => {
        const config = {
            headers: {
                "Authorization": "Token " + localStorage.getItem('token')
            }
        }

        axios.get('/api/furtherReading/', config).then((response) => {
            console.log(response.data)
            setFurtherReading(response.data)
            setLoading(false)
        })
        
    };

    return (
        <>
            <MenuButtonInactive />
            <BackButton />
            {
            loading ? <PropagateLoader color={'#ffffff'} loading={loading} size={15} />
            :
            <>
                <div className = 'intro-div-surrounding'>
                <div className = 'padding-top-5' />
                    <IntroHeading text = 'Further Reading' />
                    <div className = 'margin-top-5' />
                    <div className = 'center'>
                    {
                        furtherReadings.map((furtherReading) => <div key = {furtherReading.id}>
                            {
                                furtherReading.pdf_link.split('.').pop() === 'pdf' ? 
                                    <Link to = {{pathname: `/furtherReading/${furtherReading.id}`, state: furtherReading}}><Card text = {furtherReading.heading} /></Link> : 
                                    <Link to = {{pathname: furtherReading.web_link}} target = '_blank'><Card text = {furtherReading.heading} /></Link>
                            }
                        <div className = 'margin-top-1-5' /></div>)
                    }
                    </div>

                </div>
            </>
            }
        </>
    )
}

export default FurtherReading;
