import { Link } from 'react-router-dom'
import CardCaseStudy from '../components/CardCaseStudy'
import Card from "../components/Card"
import BackButton from '../components/BackButton'
import MenuButtonInactive from '../components/MenuButtonInactive'
import IntroHeading from '../components/IntroHeading'
import IntroDescription from "../components/IntroDescription"
import axios from 'axios'
import { useEffect, useState } from 'react'
import PropagateLoader from "react-spinners/PropagateLoader"

const Favourites = () => {

    const [caseStudies, setCaseStudies] = useState([])
    const [researches, setResearches] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const getFavourites = async () => {
            const config = {
                headers: {
                    "Authorization": "Token " + localStorage.getItem('token')
                }
            }

            const caseStudyResponse = await axios.get('/api/caseStudies/', config);
            const movingForwardResponse = await axios.get('/api/movingForwards/', config);
            const response = await axios.get('/api/favourites/', config);

            response.data.forEach(item => {
                if (item.category === 'Case Study') {
                    caseStudyResponse.data.forEach(caseStudy => {
                        if ((item.link).split('/')[2] === (caseStudy.id).toString()) {
                            setCaseStudies(prevState => [...prevState, caseStudy]);
                        }
                    })
                } else {
                    movingForwardResponse.data.forEach(research => {
                        if ((item.link).split('/')[2] === (research.id).toString()) {
                            setResearches(prevState => [...prevState, research]);
                        }
                    })
                }
            });

            setLoading(false);
    
        }

        getFavourites();

    }, []);

    return (
        <>
            <BackButton />
            <MenuButtonInactive />
            {
            loading ? <PropagateLoader color={'#ffffff'} loading={loading} size={15} />
            :
            <>
                <div className = 'margin-top-15' />
                <div className = 'padding-top-5' />
                <IntroHeading text = 'Favourites' />
                <div className = 'padding-top-5' />
                <div className = 'margin-both-side'>
                </div>
                <div className = 'center'>
                    
                    {
                        caseStudies.length > 0 && 
                        <>
                            
                            <IntroDescription text = 'Case Studies' />

                            <div className = 'padding-top-5' />

                            {
                                // here passing single case study as array because case study page need array
                                caseStudies.sort((a, b) => (a.id > b.id) ? 1 : -1).map((caseStudy) => <div key = {caseStudy.id}><Link to = {{pathname: `/caseStudy/${caseStudy.id}`, state: caseStudies}}><CardCaseStudy data = {caseStudy} /></Link><div className = 'margin-top-1-5' /></div>)
                            }
                        
                        </>
                    }

                    {
                        researches.length > 0 && 
                        <>
                            <div className = 'padding-top-5' />

                            <IntroDescription text = 'Moving Forward' />

                            <div className = 'padding-top-5' />

                            {
                                researches.sort((a, b) => (a.id > b.id) ? 1 : -1).map((research) => <div key = {research.id}><Link to = {{pathname: `/personalResearch/${research.id}`, state: researches}}><Card text = {research.heading} /></Link><div className = 'margin-top-1-5' /></div>)
                            }
                        
                        </>
                    }
                </div>
                <div className = 'padding-top-5' />
            </>
            }
        </>
    );
}

export default Favourites;
