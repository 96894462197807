import classes from './CardCaseStudy.module.css'

const CardCaseStudy = (props) => {

    return (
        <div className = {classes.card}>
            <div className = {classes.verticalcenter}>
                <div className = {classes.tile}><img src = {props.data.image_link} alt = {props.data.image_alt}></img></div>
                <div className = {classes.text}>{props.data.heading}</div>
            </div>
            
        </div>

    )
}

export default CardCaseStudy;