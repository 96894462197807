import MenuButtonInactive from "../components/MenuButtonInactive";
import BackButton from "../components/BackButton";
import IntroHeading from "../components/IntroHeading";
import IntroDescription from "../components/IntroDescription";
import IntroSubHeading from "../components/IntroSubHeading";
import { useEffect } from 'react'

const BereavementSupport = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
    }, []
  )

  return (
    <>
      <MenuButtonInactive />
      <BackButton />
      <div className="intro-div-surrounding">
        <div className="padding-top-5" />
        <IntroHeading text="Bereavement" />
        <IntroHeading text="Support" />
        <div className="margin-top-5" />

        <IntroDescription text="National Support services:" />

        <div className="margin-top-3" />
        <IntroSubHeading text="Cruse Bereavement Care" />
        <div>
          <a href="https://www.cruse.org.uk">www.cruse.org.uk</a>
          <div className="margin-top-3" />
          Cruse has branches across the U.K. providing bereavement support, find
          your local branch here:
          <br />
          <a href="https://www.cruse.org.uk/get-help/local-services">
            www.cruse.org.uk/get-help/local-services
          </a>
          <div className="margin-top-3" />
          Cruse also runs a national helpline that offers support, advice, and
          information when someone dies
          <br />
          Free helpline number: 0808 808 1677. Monday and Friday
          9:30-5pm.Tuesday, Wednesday, and Thursday 9:30-8pm (excluding bank
          holidays)
        </div>

        <div className="margin-top-3" />
        <IntroSubHeading text="Lullaby Trust" />
        <div>
          <a href="https://www.lullabytrust.org.uk">www.lullabytrust.org.uk</a>
          <div className="margin-top-3" />
          National helpline that provides a confidential support service for
          families who experience the sudden loss of a baby or toddler
          <br />
          Helpline: 0808 802 6868 (Monday – Friday: 10am-5pm, Weekends and
          public holidays: 6pm-10pm).
          <br />
          support@lullabytrust.org
        </div>

        <div className="margin-top-3" />
        <IntroSubHeading text="Sue Ryder" />
        <div>
          <a href="http://support.sueryder.org">www.support.sueryder.org</a>
          <div className="margin-top-3" />
          Advice and Support / coping with grief
        </div>

        <div className="margin-top-3" />
        <IntroSubHeading text="The Samaritans" />
        <div>
          <a href="http://www.samaritans.org">www.samaritans.org</a>
          <div className="margin-top-3" />
          116 123 (UK – direct dial, free call)
        </div>

        <div className="margin-top-3" />
        <IntroSubHeading text="Child Bereavement UK" />
        <div>
          <a href="https://www.careforthefamily.org.uk/">
            www.careforthefamily.org.uk/
          </a>
          <div className="margin-top-3" />
          Provides support for the death of a child of any age
          <br />
          Helpline: 029 2081 0800 (Monday 10am-5pm, Tuesday-Thursday 9am-5pm,
          Friday 9am – 4:30pm)
          <br />
          mail@cff.org.uk
        </div>

        <div className="margin-top-3" />
        <IntroSubHeading text="Marie Curie" />
        <div>
          <a href="https://www.mariecurie.org.uk/">www.mariecurie.org.uk/</a>
          <div className="margin-top-3" />
          0800 090 2309
        </div>

        <div className="margin-top-3" />
        <IntroSubHeading text="Blue Cross for Pets" />
        <div>
          <a href="https://www.bluecross.org.uk/">www.bluecross.org.uk</a>
          <div className="margin-top-3" />
          Offers support to anyone grieving for the loss of a pet
          <br />
          Helpline: 0800 096 6606 (Monday-Sunday 8:30am-8:30pm)
          <br />
          pbssmail@bluecross.org.uk
        </div>

        <div className="margin-top-3" />
        <IntroSubHeading text="Carer’s resource" />
        <div>
          <a href="http://www.carersresource.org/">www.carersresource.org</a>
        </div>

        <div className="margin-top-3" />
        <IntroSubHeading text="Winston wish" />
        <div>
          <a href="http://www.winstonswish.org.uk/">www.winstonswish.org.uk</a>
        </div>

        <div className="margin-top-3" />
        <IntroDescription text="West Yorkshire" />
        <IntroDescription text="Bradford" />

        <div className="margin-top-3" />
        <IntroSubHeading text="Bradford Bereavement Support" />
        <div>
          <a href="https://www.bradfordbereavement.org.uk/">
            www.bradfordbereavement.org.uk
          </a>
          <div className="margin-top-3" />
          Provide bereavement counselling for people in the Bradford area
          <br />
          01274 619 522
          <br />
          info@bradfordbereavement.org.uk
        </div>

        <div className="margin-top-3" />
        <IntroSubHeading text="Cruise Bereavement Care (Craven and Bradford District)" />
        <div>
          <a href="https://www.cruse.org.uk/get-help/local-services/yorkshire-and-humber/craven-and-bradford-district">
            www.cruse.org.uk/get-help/local-services/yorkshire-and-humber/craven-and-bradford-district
          </a>
          <div className="margin-top-3" />
          Provide bereavement support from a trained volunteer
          <br />
          01756 797 799 (Mon 2-4pm, Wed 5-7pm, Fri 10-12noon)
        </div>

        <div className="margin-top-3" />
        <IntroSubHeading text="University of Bradford" />
        <div>
          <div className="margin-top-3" />
          Student Counselling
          <br />
          01274 235750
          <br />
          counselling@bradford.ac.uk
        </div>

        <div className="margin-top-3" />
        <IntroSubHeading text="University of Bradford" />
        <div>
          <div className="margin-top-3" />
          Chaplaincy
          <br />
          07754 715584
          <br />
          chaplain@bradford.ac.uk
        </div>

        <div className="margin-top-3" />
        <IntroSubHeading text="Palliative care support" />
        <div>
          <a href="http://www.palliativecare.bradford.nhs.uk/Pages/UsefulLinks.aspx">
            www.palliativecare.bradford.nhs.uk
          </a>
        </div>

        <div className="margin-top-3" />
        <IntroSubHeading text="Bradford Cancer Support" />
        <div>
          <a href="http://www.bradfordcancersupport.org.uk/">
            www.bradfordcancersupport.org.uk
          </a>
        </div>

        <div className="margin-top-3" />
        <IntroDescription text="Sheffield" />

        <div className="margin-top-3" />
        <IntroSubHeading text="Cruise Bereavement Care (Sheffield)" />
        <div>
          <a href="https://www.cruse.org.uk/get-help/local-services/yorkshire-and-humber/sheffield">
            www.cruse.org.uk/get-help/local-services/yorkshire-and-humber/sheffield
          </a>
          <div className="margin-top-3" />
          Provide bereavement support from a trained volunteer
          <br />
          0114 249 3328
          <br />
          sheffield@cruise.org.uk
        </div>

        <div className="margin-top-3" />
        <IntroSubHeading text="Bereavement Support – Cathedral House" />
        <div>
          <div className="margin-top-3" />
          Sheffield. 0114 272 2522 office@stmariecathedral.org
        </div>

        <div className="margin-top-3" />
        <IntroDescription text="Manchester" />

        <div className="margin-top-3" />
        <IntroSubHeading text="Cruise Bereavement Care (Manchester)" />
        <div>
          <a href="https://www.cruse.org.uk/get-help/local-services/north-west/manchester">
            www.cruse.org.uk/get-help/local-services/north-west/manchester
          </a>
          <div className="margin-top-3" />
          Offer support and help to those who are grieving
          <br />
          07377 710 382
          <br />
          manchester@cruise.org.uk
        </div>

        <div className="margin-top-3" />
        <IntroDescription text="Further sources of interest:" />

        <div className="margin-top-3" />
        <div>
          <a href="http://www.dyingmatters.org/">Dying matters</a>
          <br />
          <a href="http://www.livinganddyingwell.org.uk/ ">
            Living and dying well
          </a>
          <br />
          <a href="http://www.ncpc.org.uk/">NCPC</a>
          <br />
          <a href="https://continuingbonds.live/">Continuing Bonds</a>
          <br />
          <a href=" http://deathcafe.com/">Death Café</a>
          <br />
          <div className="margin-top-3" />
        </div>
      </div>
    </>
  );
};

export default BereavementSupport;
