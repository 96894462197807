import { useHistory } from 'react-router-dom';
import classes from './BackButton.module.css'

const BackButton = () =>{

  const classContainer = classes.container + ' back-button-container'

  let history = useHistory();

  const goBack = () => {
    history.goBack()
  }

    return (
      <div className = {classContainer} onClick = {goBack}>
        <div className={classes.bar1} ></div>
        <div className={classes.bar2} ></div>
      </div>
    );
}

export default BackButton;
