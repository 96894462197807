import { useState } from 'react'
import classes from './Tag.module.css'

const Tag = (props) => {

    const [isFiltered, setIsFiltered] = useState(false)
    // const [styleClass, setStyleClass] = useState(classes.card + ' ' + classes.inactive)

    const active = classes.card + ' ' + classes.active
    const inactive = classes.card + ' ' + classes.inactive

    const setClick = () => {
        // if the tag is clicked set isFiltered to true if not already true
        // if isFiltered already true then set it to false
        
        if (!isFiltered) {
            props.onClick(props.text, props.style, true)
            setIsFiltered(true)
        } else {
            props.onClick(props.text, props.style, false)
            setIsFiltered(false)
         }
    }


    return (
        <div className={isFiltered ? active : inactive} onClick = {setClick}>{props.text}</div>
    )
}

export default Tag;
