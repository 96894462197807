import { useState } from 'react'
import classes from './AnswerCard.module.css'

const AnswerCardSingle = (props) => {

    const [isActive, setIsActive] = useState(false)
    const [index, setIndex] = useState()
    const [selectedIndex, setSelectedIndex] = useState()

    const answers = props.answers

    const active = classes.flex + ' ' + classes.active
    const inactive = classes.flex + ' ' + classes.inactive

    const setClick = (index) => {
        // if the tag is clicked set index variable to hold the index of clicked one
        // if the clicked index not equal to already selected index then make the index as active index
        // and make assign that index as new selected index
        setIndex(index)

        if (selectedIndex === index) {
            setIsActive(false)
            setSelectedIndex(-1) // setting selected index to not existing index value, otherwise after active deactivated again it will not goto active unless click other option
            props.onClick(props.objName, null) // this will tell the current object name is null so show the other option input
        } else {
            setIsActive(true)
            props.onClick(props.objName, answers[index])
            setSelectedIndex(index)
        }
        
        // if (!isActive) {
        //     setIsActive(true)
        //     props.onClick(props.objName, answers[index])
        // } else {
        //     setIsActive(false)
        //     props.onClick(props.objName, undefined)
        //  }
    }


    return (
        answers.map((value, arrIndex) => {
            return <div className={isActive && arrIndex === index ? active : inactive} onClick = {() => setClick(arrIndex)} key = {arrIndex}>{value}</div>
        })
    )
}

export default AnswerCardSingle;
