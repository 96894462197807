import MenuButtonInactive from "../components/MenuButtonInactive";
import BackButton from "../components/BackButton";
import IntroHeading from "../components/IntroHeading";
import IntroDescription from "../components/IntroDescription";
import { useEffect } from 'react'

const SelfCare = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
        }, []
    )

    return (
        <>
            <MenuButtonInactive />
            <BackButton />
            <div className = 'intro-div-surrounding'>
                <div className = 'padding-top-5' />
                <IntroHeading text = 'Self Care' />
                <div className = 'margin-top-5' />
                <IntroDescription text = 'Ongoing self-care is important, particularly when talking to clients about themes relating to death, dying, bereavement and loss. Here are some points and suggestions to keep in mind whilst using this toolkit:' />
                <div className = 'margin-top-3' />
                <ul>
                    <li>Make time for yourself- include regular scheduled breaks</li>
                    <br />
                    <li>Seek professional emotional support if you need to</li>
                    <br />
                    <li>Do not neglect your hobbies</li>
                    <br />
                    <li>Exercise on a regular basis and try to maintain a healthy diet</li>
                    <br />
                    <li>Set boundaries between yourself and clients</li>
                    <br />
                    <li>Take holiday days / time out</li>
                    <br />
                    <li>Seek support from your peers if you need to</li>
                    <br />
                    <li>Get outside. Sunlight lifts the mood and is important for regulating mood states. Also, being in nature has been shown to reduce stress levels by 30% in just 20 minutes.</li>
                    <br />
                    <li>Keep a journal – writing your thoughts down has been shown to reduce negative moods and stress.</li>
                    <br />
                    <li>Meditate – this practice can help calm the mind and reduce stress by bringing focus to the present</li>
                </ul>
                <div className = 'margin-top-2' />
            </div>
        </>
    )
}

export default SelfCare;
