import { useParams } from "react-router-dom";
import BackButton from "../components/BackButton";
import MenuButtonInactive from "../components/MenuButtonInactive";
import IntroHeading from "../components/IntroHeading";
import IconStar from "../components/IconStar";
import CaseStudyQuestion from "./CaseStudyQuestion";
import IconQuestion from "../components/IconQuestion";
import IconFullscreen from "../components/IconFullscreen";
import ModalCaseStudy from "../components/ModalCaseStudy";
import { useState, useEffect } from "react";
import axios from "axios";
import PropagateLoader from "react-spinners/PropagateLoader";

const CaseStudy = (props) => {
  // getting case studies from the props
  // const caseStudies = props.location.state;
  const propsData = props.location.state;
  const [caseStudies, setCaseStudies] = useState([]);
  const { id } = useParams();
  const [currentCaseStudyIndex, setCurrentCaseStudyIndex] = useState([]);
  const [caseStudy, setCaseStudy] = useState([]);
  const [showQuestion, setShowQuestion] = useState(false);
  const [modal, setModal] = useState(localStorage.getItem('show_casestudy_popup'))
  const [showModal, setShowModal] = useState(false) // this is to send the status of check box to the modal component
  const [showFullScreen, setShowFullScreen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const config = {
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    };

    const getCaseStudy = async () => { // if this comes from case studies window then props will be provided and having data of all case studies
      if (propsData) {
        console.log('inside if');
        setCaseStudies(propsData);
        setCurrentCaseStudyIndex(propsData.findIndex((o) => o.id === parseInt(id)));
        setCaseStudy(propsData[propsData.findIndex((o) => o.id === parseInt(id))]);
      } else { // else if visited using individual link then need to download the case studies 
        const response = await axios.get("/api/caseStudies/", config);
        console.log('inside else')
        console.log(response.data);
        setCaseStudies(response.data);
        setCurrentCaseStudyIndex(response.data.findIndex((o) => o.id === parseInt(id)));
        setCaseStudy(response.data[response.data.findIndex((o) => o.id === parseInt(id))]);
      }

    }
    getCaseStudy();
    }, [propsData, id]
  );

  const setPrevious = () => {
    // if the case study id with decreasing one available in the array make new id to case study with the decremented value
    if (currentCaseStudyIndex > 0) {
      setCaseStudy(caseStudies[currentCaseStudyIndex - 1]);
      setCurrentCaseStudyIndex(currentCaseStudyIndex - 1);
      // setCaseStudyid(caseStudyid - 1);
      // nned to update router according to the case study id to keep the case study on refresh
      // window.history.replaceState(null, "New Page Title", "/caseStudy/" + (caseStudyid - 1));
    }
    resetScroll()
  };

  const setNext = () => {
    if (currentCaseStudyIndex < caseStudies.length - 1) {
      setCaseStudy(caseStudies[currentCaseStudyIndex + 1]);
      setCurrentCaseStudyIndex(currentCaseStudyIndex + 1);
      // window.history.replaceState(null, "New Page Title", "/caseStudy/" + (caseStudyid + 1));
    }
    resetScroll()
  };

  const hideModal = () => {
    setModal('false')
  };

  const resetScroll = () => {
    // document.getElementById('case-study-body').scrollTop = 0
    window.scrollTo(0, 0)
  }

  if (caseStudies.length > 0) { // if case study already loaded and comes from props then show case studies

    return (
  
      showQuestion ? 
        <CaseStudyQuestion caseStudy={caseStudy} clickClose = {() => setShowQuestion(false)}/> :
        showFullScreen ?
        <div className='case-study-full-screen'>
          <div onClick={() => {setShowFullScreen(false)}}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </div>
          <img
          src={caseStudy.image_link}
          alt={caseStudy.image_alt}
          />
        </div>
  
        :
          <>
            <BackButton />
            <MenuButtonInactive />
            {
              modal !== 'true' ? 
              <>
              <div className="margin-top-15" />
      
              <div className="case-study-body" id = "case-study-body">
                <div className="case-study-outer">
                  <div className="case-study-container">
                    <div className="case-study-navigation">
                      <div className="left">
  
                          
                        <i
                        className = { currentCaseStudyIndex > 0 ? "fa fa-angle-left case-study-navigation-active" : "fa fa-angle-left case-study-navigation-inactive" }
                        aria-hidden = "true"
                        onClick = { currentCaseStudyIndex > 0 ? setPrevious : null }
                        />
  
                      </div>
                      <div className="right">
  
                        <i
                        className = { caseStudies && currentCaseStudyIndex < caseStudies.length - 1 ? "fa fa-angle-right case-study-navigation-active" : "fa fa-angle-right case-study-navigation-inactive" }
                        aria-hidden = "true"
                        onClick = { caseStudies && currentCaseStudyIndex < caseStudies.length - 1 ? setNext : null }
                        />
  
                      </div>
                    </div>
      
                    <div className="case-study-overlay">
                      <IconQuestion showQuestion = {() => setShowQuestion(true)}/>
                      <IconStar
                        data={{
                          link: `/caseStudies/${caseStudy.id}/`,
                          category: "Case Study",
                        }}
                      />
                      <IconFullscreen showFullscreen = {() => setShowFullScreen(true)}/>
                      
                    </div>
      
                    <img
                      src={caseStudy.image_link}
                      alt={caseStudy.image_alt}
                      className="case-study-img"
                    />
                  </div>
                </div>
                <div className="case-study-text-container">
                  <div>
                    <IntroHeading text={caseStudy.heading} />
                  </div>
      
                  <div
                    dangerouslySetInnerHTML={{
                      __html: caseStudy.content,
                    }}
                  ></div>
                </div>
              </div>
              <div className="shadow"></div>
              </> :
              <ModalCaseStudy show={modal} handleClose={hideModal} checkedStatus = {showModal}>
                <p>These case studies have been compiled based on the grief theories explored in the modules. These are examples from around the world and from different time periods. Please explore cautiously as some materials may be upsetting to some people.</p>
                <input type = "checkbox" id = "doNotShowCheck" checked = {showModal} onChange = {() => {setShowModal(!showModal); console.log(showModal)}}/>
                <label htmlFor = "doNotShowCheck">Do not show this again</label>
                <div className="margin-top-5" />
              </ModalCaseStudy>
  
            }
            
          </>
      
    );

  } else { // if case study visited from the link without provided props then show loader till the data load from server
    return (
      <div className="loading">
          <PropagateLoader color={'#ffffff'} size={15} />
      </div>
    );
  }


};

export default CaseStudy;
