import classes from './AboutLogo.module.css'

const AboutLogo = (props) => {
    return (
        <div className = {classes.container}>
            <span className={classes.helper}></span>
            <img className = {classes.img}
                src = {props.src}
                alt = {props.alt}
            />
        </div>
    )
}

export default AboutLogo;