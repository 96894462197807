import classes from './MenuButton.module.css'
import {Link} from 'react-router-dom'

const MenuButton = () => {

    return (
        <Link to = '/menu'>
            <div className = {classes.container}>
                <div className={classes.bar1} ></div>
                <div className={classes.bar2} ></div>
                <div className={classes.bar3} ></div>
            </div>
        </Link>
    )
}

export default MenuButton