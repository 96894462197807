import BackButton from "../components/BackButton";
import MenuButtonInactive from "../components/MenuButtonInactive";
import IntroHeading from "../components/IntroHeading";
import IntroDescription from "../components/IntroDescription";
import { useEffect } from 'react'


const CookieStatement = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
        }, []
    )
    
    return (
        <>
            <MenuButtonInactive />
            <BackButton />
            <div className = 'intro-div-surrounding'>
                <div className = 'padding-top-5' />
                <IntroHeading text = 'Cookie Statement' />
                <div className = 'margin-top-3' />
                <div>
                    The Continuing Bonds Toolkit uses small text files called cookies. This statement provides information on what cookies are and how they can be controlled.
                    <div className = 'margin-top-2'></div>
                    If you wish to restrict or block cookies you can set your internet browser to do so - click on the following link for further information: <a href = 'https://www.aboutcookies.org/'>www.aboutcookies.org</a>
                </div>
                <div className = 'margin-top-2'></div>
                <IntroDescription text = '1. What are cookies?' />
                <div className = 'margin-top-2'></div>
                <div> 
                    Cookies are text files containing small amounts of information which are downloaded to your device when you visit a website. Cookies are then sent back to the originating website on each subsequent visit, or to another website that recognises that cookie. Cookies are widely used because they allow a website to recognise a user’s device and therefore allow enhanced functionality.
                    <div className = 'margin-top-2'></div>
                    You can find out more information about cookies at: <a href = 'https://www.allaboutcookies.org/'>www.allaboutcookies.org</a> some definitions used in this note are provided at part 4 below.
                </div>
                <div className = 'margin-top-2'></div>
                <IntroDescription text = '2. How does the Continuing Bonds Toolkit use cookies?' />
                <div className = 'margin-top-2'></div>
                <div>
                    The Continuing Bonds Toolkit uses cookies for the following purposes:
                    <div className = 'margin-top-2'></div>
                    to compile statistical data on the use of our website and its functioning, so that we can monitor and improve it; and
                    <div className = 'margin-top-2'></div>
                    to facilitate users’ ability to save certain aspects of the toolkit to a favourites section, including to allow registered users to access secure parts of our website.
                </div>
                <div className = 'margin-top-2'></div>
                <IntroDescription text = '3. How to control cookies' />
                <div className = 'margin-top-2'></div>
                <div>
                    If you do not wish to receive cookies you can set your web browser to restrict or block them. All modern browsers allow you to change your cookie settings, typically found in the ‘options’ or ‘preferences’ menu of your browser. In order to understand these settings, the following link may be helpful: <a href = 'https://www.aboutcookies.org/'>www.aboutcookies.org</a>
                    <div className = 'margin-top-2'></div>
                    If you disable cookies you will not be able to use all the interactive features of the toolkit.
                </div>
                <div className = 'margin-top-2'></div>
                <IntroDescription text = '4. Definitions' />
                <div className = 'margin-top-2'></div>
                <div>
                    <b>First Party/ Third party cookies</b><br />
                    Refers to the web domain placing the cookie. First-party cookies are those set by a website that is being visited by the user at the time – the website displayed in the URL window. Third party cookies are cookies that are set by a domain other than that of the website being visited by the user. If a user visits a website and another entity sets the cookie through that website, this would be a third-party cookie.
                    <div className = 'margin-top-2'></div>
                    <b>Persistent cookies</b><br />
                    These cookies remain on a user’s device for the period of time specified in the cookie. They are activated each time that the user visits the website that created that particular cookie.
                    <div className = 'margin-top-2'></div>
                    <b>Session cookies</b><br />
                    These cookies allow website operators to link the actions of a user during a browser session. A browser session starts when a user opens the browser window and finishes when they close the browser window. Session cookies are created temporarily. Once you close the browser, all session cookies are deleted.
                    <div className = 'margin-top-2'></div>
                    <b>Pixel tags</b><br />
                    A pixel tag is not technically a cookie but is a similar type of technology placed on a website or within the body of an email for the purpose of tracking activity on websites, or when emails are opened or accessed, and is often used in combination with cookies. Information collected is anonymous.
                </div>
                
            </div>

        </>
    );
}

export default CookieStatement;
