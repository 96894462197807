import { useParams } from 'react-router-dom'
import BackButton from '../components/BackButton'
import MenuButtonInactive from '../components/MenuButtonInactive'
import IntroHeading from '../components/IntroHeading'
import IconStar from '../components/IconStar'
import { useState, useEffect } from 'react'
import VideoPlayer from '../components/VideoPlayer'

const PersonalResearch = (props) => {
      // getting personal researches from the props
    const personalResearches = props.location.state
    const { id } = useParams()
    const [currentResearchIndex, setCurrentResearchIndex] = useState(personalResearches.findIndex((o) => o.id === parseInt(id)));
    const [ personalResearch, setPersonalResearch ] = useState(personalResearches[currentResearchIndex]);

    useEffect(() => {
        window.scrollTo(0, 0)
        }, []
    )
      

    const setPrevious = () => {
        // decrease array index by one and assign the value on decreased index
        if (currentResearchIndex > 0) {
            setPersonalResearch(personalResearches[currentResearchIndex - 1]);
            setCurrentResearchIndex(currentResearchIndex - 1);
            // setCaseStudyid(caseStudyid - 1);
            // nned to update router according to the case study id to keep the case study on refresh
            // window.history.replaceState(null, "New Page Title", "/caseStudy/" + (caseStudyid - 1));
        }
        
    }

    const setNext = () => {
        if (currentResearchIndex < personalResearches.length - 1) {
            setPersonalResearch(personalResearches[currentResearchIndex + 1]);
            setCurrentResearchIndex(currentResearchIndex + 1);
            // window.history.replaceState(null, "New Page Title", "/caseStudy/" + (caseStudyid + 1));
        }
        

    }

    return (
        <>
        <BackButton />
        <MenuButtonInactive />
        <div className = 'margin-top-15' />

        {/* case study style class is suitable for personal research so those used below */}

        <div className='case-study-body' id='case-study-scroll'>
            <div className='case-study-outer'>
                <div className='case-study-container'>

                <div className='case-study-navigation personal-research-navigation'>
                    
                    <div className='left'>

                        <i 
                        className = { currentResearchIndex > 0 ? "fa fa-angle-left case-study-navigation-active" : "fa fa-angle-left case-study-navigation-inactive" } 
                        aria-hidden = "true" 
                        onClick = { currentResearchIndex > 0 ? setPrevious : null }
                        />
                       
                    </div>
                    <div className='right'>
                             
                        <i 
                        className = { currentResearchIndex < personalResearches.length - 1 ? "fa fa-angle-right case-study-navigation-active" : "fa fa-angle-right case-study-navigation-inactive" } 
                        aria-hidden = "true" 
                        onClick = { currentResearchIndex < personalResearches.length - 1 ? setNext : null }
                        />

                    </div>
                    
                </div>

                    <div className='case-study-overlay'>
                        <IconStar 
                         data = {{
                             link: `/movingForwards/${personalResearch.id}/`, 
                             category: 'Personal Research'
                             }} 
                        />
                    </div>
                    
                    <img src = {personalResearch.image_link} alt = {personalResearch.image_alt} className = 'case-study-img' />
                    
                </div>

            </div>
            <div className='case-study-text-container'>
                <div >
                    <IntroHeading text = {personalResearch.heading} />
                </div>
                        
                <div dangerouslySetInnerHTML={{
                        __html: personalResearch.content
                    }}>
                </div>
                <VideoPlayer url = {personalResearch.video_URL}/>
            </div>
    
        </div>
        <div className="shadow"></div>
        </>
    )

}

export default PersonalResearch;
