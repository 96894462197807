import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import 'typeface-roboto';
import { BrowserRouter } from 'react-router-dom'
import axios from 'axios';
import 'font-awesome/css/font-awesome.min.css'
import { QueryClientProvider, QueryClient } from "react-query";
import { FpjsProvider } from '@fingerprintjs/fingerprintjs-pro-react';

const queryClient = new QueryClient();

// this will setup base url configuration for axios to avoid putting api baseurl
// axios.defaults.baseURL = 'http://localhost:8000/'
// axios.defaults.baseURL = 'https://runedigital-cb-back.herokuapp.com/'
// axios.defaults.baseURL = 'https://cb-vm.ukwest.cloudapp.azure.com/'
axios.defaults.baseURL = 'https://cb-toolkit.bradford.ac.uk/'


ReactDOM.render(
  <React.StrictMode>
     
     <BrowserRouter>
     <QueryClientProvider client={queryClient}>
      <FpjsProvider
          loadOptions={{
            apiKey: "ODTkk203KcNIl9t867Yd", region: "eu"
          }}
      >
     	<App />
      </FpjsProvider>
     </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
