import "./Modal.css";
import { useHistory } from "react-router-dom";

const Modal = ({ handleClose, show, children }) => {

  const showHideClassName = show ? "modal display-block" : "modal display-none"
  const history = useHistory()

  const navigate = () => {
    history.push('/feedback')
    handleClose()
  }

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        {children}
        <div className="modal-button">
          <button type="button" onClick={navigate}>Yes</button>
          <button type="button" onClick={handleClose}>No</button>
        </div>

      </section>
    </div>
  )
}

export default Modal