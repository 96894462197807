import { useEffect, useState } from 'react';
import axios from 'axios';
import Error from '../components/Error'

const UnsubscribeEmail = () => {

    const [unsubscribed, setUnsubscribed] = useState(false);

    const message = unsubscribed ? 'You\'ll no longer receive emails from Continuing Bonds' : ''
    const title = unsubscribed ? 'You\'ve unsubscribed' : ''
    const queryParams = new URLSearchParams(window.location.search)
    const auth = queryParams.get('auth')
    const temp_token = queryParams.get('temp_token')

    useEffect(() => {

        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        }

        axios.get('/api/auth/unsubscribe?auth=' + auth + '&temp_token=' + temp_token, config).then(res => {
            if (res.data.message === 'success')
                setUnsubscribed(true)
        }).catch(err => {
            console.log(err)
        })

    })

    return (
        <>
            <div className="center auth-surrounding">
                <div className="padding-top-5"></div>
                <div className="toolkit-title">Continuing Bonds Toolkit</div>
                <div className="padding-top-5"></div>
                <div className="err">
                    <div className="margin-top-1" />
                    <Error header={title} content={message} />
                </div>
            </div>
        </>
    )
}

export default UnsubscribeEmail