import BackButton from "../components/BackButton";
import MenuButtonInactive from "../components/MenuButtonInactive";
import IntroHeading from "../components/IntroHeading";
import IntroDescription from "../components/IntroDescription";
import howtouseDiagram from '../images/howtouse_diagram.jpg'
import HowToUseDiagram from "../components/HowToUseDiagram";
import { useEffect } from 'react'


const HowToUse = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
        }, []
    )
    
    return (
        <>
            <MenuButtonInactive />
            <BackButton />
            <div className = 'intro-div-surrounding'>
                <div className = 'padding-top-5' />
                <IntroHeading text = 'How to use the' />
                <IntroHeading text = 'Toolkit' />
                <div className = 'margin-top-5' />
                <IntroDescription text = 'The toolkit has a dual purpose:' />
                <br />
                <IntroDescription text = '1) It is designed as a resource to aid Continuing Professional Development (CPD), particularly for counsellors, therapists, and anyone working with those facing loss;' />
                <br />
                <IntroDescription text = '2) it can also be used by individuals, or talked through with clients (at your professional discretion), worked through with a therapist, or as self-guided tasks by those facing loss. If you are using this as an individual, please take care and ensure you understand the ‘ground rules’ around self-care.' />
                <div className = 'margin-top-3' />
                <div>
                    The toolkit has been designed by an interdisciplinary team including specialists in archaeology, psychology, counselling, and bereavement, and with educators and practitioners, and those experiencing bereavement. 
                </div>
                <div className = 'margin-top-2'></div>
                <div> 
                    The toolkit is a result of research into the use of archaeology for normalising talk about death, dying and bereavement. The original project, ‘Continuing Bonds: exploring the meaning and legacy of death through past and contemporary practice’ (2016-18) was a two-year collaboration between archaeology and healthcare at the University of Bradford and LOROS Hospice, Leicester, funded by the Arts and Humanities Research Council (AHRC). The project team found that providing a safe space, using materials which provided cognitive dissonance (that challenge what we may think of as ‘normal’ and take us outside of our comfort zone), enabled discussion, and encouraged participants (who were health and social care professions and students) to think differently about death and bereavement, as well as normalising and validating diverse experiences of bereavement. 
                </div>
                <div className = 'margin-top-2'></div>
                <div>
                    The toolkit is modular, meaning you can select the areas more appropriate for your CPD or for your client. It is designed with Bloom's learning taxonomy in mind (Anderson and Krathwohl 2001), which sees a development within each module from acquiring and understanding knowledge,  through to deeper analysis and engagement, with suggested tasks which enable you to create your own resources if you wish to.
                </div>
                <div className = 'margin-top-3' />
                <HowToUseDiagram src={howtouseDiagram} alt = 'Diagram of a taxonomy for learning, teaching, and assessing' />
                <div>
                    <i>Image by Jessica Shabatura,<br />
                    <a href = 'https://tips.uark.edu/using-blooms-taxonomy/'>https://tips.uark.edu/using-blooms-taxonomy/</a></i>
                </div>
                <div className = 'margin-top-2' />
            </div>
        </>
    );
}

export default HowToUse;
