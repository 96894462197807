import React, { useState, useEffect } from 'react'
import axios from 'axios';
import classes from './IconStar.module.css'

const IconStar = (props) => {

    // defining case study url link and category of favourite comes from the appropriate favourite types case study or personal research
    const urlLink = props.data.link
    const category = props.data.category

    // const [res, setResponse] = useState()
    const [isFavourite, setIsFavourite] = useState(false)
    const [favouriteId, setFavouriteId] = useState(null)

    const starCircleStyleInActive = 'fa fa-circle fa-stack-2x icon-background ' + classes.active
    const starCircleStyleActive = 'fa fa-circle fa-stack-2x icon-background ' + classes.inactive
    const starStyleInActive = 'fa fa-star fa-stack-1x ' + classes.inactive
    const starStyleActive = 'fa fa-star fa-stack-1x ' + classes.active
    // const questionStyle = `fa fa-question fa-stack-1x ${classes.inactive}`
    const stack_style = `fa-stack fa-2x ${classes.stack_style}`

    useEffect(() => {

        const getFavourite = async () => {
            // check if the case study link is added in favourite already
            // if already added then make the isFavourite variable to true
            const config = {
                headers: {
                    "Authorization": "Token " + localStorage.getItem('token')
                },
                params: {
                    link: urlLink
                }
            }
            try {

                const response = await axios.get('/api/favourites/', config);
                console.log(response.data)
                if (response.data.length) {
                    for (let i = 1; i < response.data.length; i++) {

                        await axios.delete(`/api/favourites/${response.data[i].id}/`, config)

                    }

                    setFavouriteId(response.data[0].id)
                    setIsFavourite(true)
                } else {
                    setIsFavourite(false)
                }

            } catch (err) {
                console.log('something went wrong...')
            }

        }

        getFavourite()

    }, [urlLink])

    const setFavourite = async () => {
        // if the case study is not currently marked as favourite then add to db
        if (!isFavourite) {

            const config = {
                headers: {
                    "Authorization": "Token " + localStorage.getItem('token'),
                    "Content-Type": "application/json"
                },
            }

            const body = {
                "category": category,
                "link": urlLink,
            }

            try {
                const response = await axios.post('/api/favourites/', body, config)
                setFavouriteId(response.data.id)
                setIsFavourite(true)
            } catch (err) {
                console.log('something went wrong...')
            }
        } else {
            // remove the already added case study in db
            try {

                const config = {
                    headers: {
                        "Authorization": "Token " + localStorage.getItem('token')
                    }
                }

                await axios.delete(`/api/favourites/${favouriteId}/`, config)
                setIsFavourite(false)
            } catch (err) {
                console.log('something went wrong...')
            }

        }
        
    }
// hide the question icon if the props passed have true value on hideQuestion
    return (
        <>


            <span className = {stack_style} onClick = {setFavourite}>
                <i className = {isFavourite ? starCircleStyleActive : starCircleStyleInActive}></i>
                <i className = {isFavourite ? starStyleActive : starStyleInActive}></i>
            </span>

        </>


    );
  }
  
  export default IconStar;
