import { Link } from 'react-router-dom';
import { useEffect } from "react";

import Card from "../components/Card";
import MenuButton from "../components/MenuButton";
import IntroHeading from '../components/IntroHeading'

const Home = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
    }, []
  );

  return (
      <>
        <MenuButton />
        <div className = 'margin-top-20'></div>
        <IntroHeading text = 'Continuing Bonds Toolkit' />
        <div className = 'center-vertical center'>
          <div className = 'margin-top-10' />
          <Link to = '/modules'><Card text = 'Modules' /></Link>
          <div className = 'margin-top-2'></div>
          <Link to = '/caseStudies'><Card text = 'Case Studies' /></Link>
          <div className = 'margin-top-2'></div>
          <Link to = '/movingForward'><Card text = 'Moving Forward' /></Link>
          <div className = 'margin-top-2'></div>
          <Link to = '/furtherReading'><Card text = 'Further Reading' /></Link>
          <div className = 'margin-top-2'></div>
          <Link to = '/favourites'><Card text = 'Favourites' /></Link>
        </div> 
      </>
  );
}

export default Home;
