import Header from "../components/Header";
import { useRef, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import Error from "../components/Error";
import { Link, useHistory } from "react-router-dom";
import PropagateLoader from "react-spinners/PropagateLoader";
import ReCAPTCHA from "react-google-recaptcha";
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';

export default function Login() {

  
  const emailRef = useRef();
  const { login } = useAuth();
  const [err, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [captchaResult, setCaptchaResult] = useState();
  const history = useHistory();
  const {data} = useVisitorData();

  const resetError = () => {
    setError("");
    console.log("reset error");
  };

  // const checkFeedback = () => {
  //   console.log('feedback need to be popup ' + localStorage.getItem('feedback'))
  // }

  const tryLogin = async (e) => {

    if (
      emailRef.current.value &&
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailRef.current.value)
    ) {
      if (captchaResult) {
        setLoading(true);
        const email = emailRef.current.value;
        login(email, data.visitorId)
          .then((res) => {
            // console.log(res.data.user)
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("show_casestudy_popup", res.data.user.show_casestudy_popup)

            if (res.data.token && res.data.token !== "verify") {
              history.push("/");
              localStorage.setItem("feedback", res.data.user.show_feedback_popup);
              localStorage.setItem("email", res.data.user.email)
              // setLoading(false); here no need to set it since it is unmounting the component
            } else if (res.data.token === "verify") {
              localStorage.setItem("email", res.data.user.email)
              history.push("/verification")
            } else {
              localStorage.setItem("email", res.data.user.email)
              history.push("/introduction")
            }
  
          })
          .catch((err) => {
            // check the error is non field error which is the email is not verified or device not verified
            const non_field_errors = err.response ? err.response.data.non_field_errors : null;

            if (non_field_errors) { 
              if (non_field_errors[0] === "Device not verified") {
                localStorage.setItem("email", email)
                history.push("/verifyDevice");
              } else {
                setError(
                  "" +
                    (err.response.data.non_field_errors
                      ? "This email is not registered, please sign up or enter your email correctly"
                      : "") +
                    (err.response.data.email ? err.response.data.email : "")
                );
                setLoading(false);
              }
            } else {
              // if the response of error is not there because of server down
              setError(
                "Server is too busy or cannot contacted please try again later..."
              );
              setLoading(false);
            }
          });
      } else {
        setError("Please verify that you are not a robot");
      }

    }
    else {
      setError("Please enter a valid email");
    }

  }

  const handleSubmit = async (e) => {
    // if the data from browser fingerprint is not received becuase of their server donw then throw an error
    // otherwise try to login
    e.preventDefault();
    if (!data) {
      setError("Sorry, We couldn't connect to the device verification service, Please try again later...");
    } else {
      tryLogin(e);
    }
  };

  const handleRecaptcha = (value) => {

    fetch('https://cb-toolkit.bradford.ac.uk/api/auth/recaptcha/', {
      method: 'POST',
      body: JSON.stringify({ 'captcha_value': value }),
      headers: { 'Content-Type': 'application/json' }
    })
     .then(res => res.json())
     .then(data => {
       console.log(data.captcha.success)
       setCaptchaResult(data.captcha.success)
       setError()
     }) 
  }

  return (
    <>
    <Header />
    {
      loading ? <PropagateLoader color={'#ffffff'} loading={loading} size={15} />
      :
      
      <div className="center auth-surrounding">
        <div className="padding-top-5"></div>
        <div className="toolkit-title">Continuing Bonds Toolkit</div>
        <h1>Welcome</h1>
        <div className="margin-top-10"></div>
        <input
          type="email"
          placeholder="Email"
          ref={emailRef}
          aria-label="Email"
          id="email"
          onMouseDown={resetError}
          tabIndex={1}
        />

        <div className="margin-top-3"></div>
        <div className="re-captcha">
          <ReCAPTCHA 
            sitekey={process.env.REACT_APP_SITE_KEY} 
            onChange={handleRecaptcha}
            tabIndex={2}
          />
        </div>

        {err && (
          <div className="err">
            <div className="margin-top-2" />
            <Error header="Login Error" content={err} />
          </div>
        )}
        <div className="margin-top-10"></div>
        <div className={err ? "hide-elm" : ""} tabIndex={3}>
          <button 
            disabled={loading} 
            type="submit" 
            onClick={handleSubmit}
          >
            Login
          </button>
        </div>
        <div className="margin-top-10"></div>
        Not Registered?{" "}
        <span className="pointer" tabIndex={4}>
          <Link to="/registration">Sign Up</Link>
        </span>
      </div>
    }
      
    </>
  );
}
