import Header from "../components/Header";
import { useRef, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import Error from "../components/Error";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import PropagateLoader from "react-spinners/PropagateLoader";
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';

export default function VerifyDevice() {
  const codeRef = useRef();
  const { login } = useAuth();
  const [err, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const {data} = useVisitorData();
  const history = useHistory();
  const username = localStorage.getItem('email');

  const resetError = () => {
    setError("");
    console.log("reset error");
  };

  const handleSubmit = async (e) => {
      
    e.preventDefault()

    setLoading(true)

    const config = {
      headers: {
          "Content-Type": "application/json"
      }
    }

    const code = codeRef.current.value
    const browserFingerprint = data.visitorId;
    const body = JSON.stringify({username, browser_fingerprint: browserFingerprint, device_verification_code: code});

    axios.put('/api/auth/verify_device', body, config).then((res) => {
      console.log(res.data.user);
      if (res.data.user) {
        login(username, browserFingerprint).then((res) => {
          localStorage.setItem("token", res.data.token);
          history.push("/")
        })
        
      } else {
        setError('The code entered is not matched, Please enter the correct code')
        setLoading(false)
      }
    }).catch((err) => {
      // if server not responing no response will receive so handle it here by checking that
      const non_field_errors = err.response ? err.response.data.non_field_errors : null;
      console.log(err)
      if(non_field_errors && non_field_errors[0] === "Incorrect Verification Code") {
        setError(`Incorrect Verification Code. You have ${non_field_errors[1]} attempts left`)
        setLoading(false)
      } else if(non_field_errors && non_field_errors[0] === "Device is blocked") {
        setError('Maximum Attempts Exceeded. Please contact Condinuing Bond Support for assistance through our email: continuingbondstoolkit@gmail.com')
        setLoading(false)
      } else {
        setError('Server is too busy or cannot contacted please try again later...')
        setLoading(false)
      }

    })
    
  }

  return (
    <>
      <Header />
      {
      loading ? <PropagateLoader color={'#ffffff'} loading={loading} size={15} />
      :
      <div className="center auth-surrounding">
        <div className="padding-top-5"></div>
        <div className="toolkit-title">Continuing Bonds Toolkit</div>
        <div className="margin-top-10"></div>
        <div className="verify-code-label"><b>New Device detected</b>, Please enter the device verification code received to your email:</div>
 
        <div className="margin-top-10"></div>
        <input
          type="number"
          placeholder="Verification Code"
          ref={codeRef}
          aria-label="Verification Code"
          id="verification_code"
          onMouseDown={resetError}
        />
        {err && (
          <div className="err">
            <div className="margin-top-2" />
            <Error header="Device Verification Error" content={err} />
          </div>
        )}
        <div className="margin-top-10"></div>
        <div className={err ? "hide-elm" : ""}>
          <button disabled={loading} type="submit" onClick={handleSubmit}>
            Verify
          </button>
        </div>
        
      </div>
    }
    </>
  )
}
