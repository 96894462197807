import { Link } from 'react-router-dom'
import Card from "../components/Card";
import BackButton from '../components/BackButton';
import MenuButtonInactive from '../components/MenuButtonInactive';
import { useAuth } from "../contexts/AuthContext";
import { useEffect } from "react";

const Menu = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
        }, []
    );

    const { logout } = useAuth();

    return (
        <>
        <BackButton />
        <MenuButtonInactive />
        <div className = 'center-vertical-menu center'>
            <Link to = '/about'><Card text = 'About' /></Link>
            <div className = 'margin-top-1-5'></div>
            <Link to = '/howtouse'><Card text = 'How to Use' /></Link>
            <div className = 'margin-top-1-5'></div>
            <Link to = '/groundrules'><Card text = 'Ground Rules' /></Link>
            <div className = 'margin-top-1-5'></div>
            <Link to = '/selfcare'><Card text = 'Self Care' /></Link>
            <div className = 'margin-top-1-5'></div>
            <Link to = '/bereavementsupport'><Card text = 'Bereavement Support' /></Link>
            <div className = 'margin-top-1-5'></div>
            <Link to = '/contactus'><Card text = 'Contact Us' /></Link>
            <div className = 'margin-top-1-5'></div>
            <Link to = '/feedback'><Card text = 'Feedback' /></Link>
            <div className = 'margin-top-1-5'></div>
            <Link to = '/team'><Card text = 'Team' /></Link>
            <div className = 'margin-top-1-5'></div>
            <Link to = '/accessibilityStatement'><Card text = 'Accessibility Statement' /></Link>
            <div className = 'margin-top-1-5'></div>
            <Link to = '/privacyStatement'><Card text = 'Privacy Statement' /></Link>
            <div className = 'margin-top-1-5'></div>
            <Link to = '/cookiePolicy'><Card text = 'Cookie Statement' /></Link>
            <div className = 'margin-top-1-5'></div>
            <div onClick={logout}><Card text = 'Sign out' /></div>
            <div className = 'margin-top-1-5'></div>
        </div>
        </>
    )

}

export default Menu;
