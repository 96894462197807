import classes from './Card.module.css'

const Card = (props) => {
    if (props.type) {
        return (
            <div className={classes.selector}>
                <div className = {classes.verticalcenter} >{props.text}</div>
            </div>
        )
    }
    return (
        <div className={classes.card}>{props.text}</div>
    )
}

export default Card;