// import { useHistory } from "react-router-dom";
import BackButton from "../components/BackButton";
import MenuButtonInactive from "../components/MenuButtonInactive";

const CaseStudyQuestion = (props) => {
  console.log(props.caseStudy);
  const caseStudyQuestionData =
    props.caseStudy.case_study_question;
  // console.log(props.location.state.caseStudy.case_study_question);

  // let history = useHistory();

  // const goBack = () => {
  //   // history.goBack();
  //   // console.log(props.location.state.caseStudy);
  //   // history.push("/caseStudy/" + props.location.state.caseStudy.id);
  // };

  return (
    <>
      <BackButton />
      <MenuButtonInactive />
      <div className="margin-top-15" />
      <div className="padding-top-5" />

        <div className="case-study-question-card">
          <div className="title">
            <div className="text">To Think About</div>
            <div className="btn-close" onClick={props.clickClose}>
              <i className="fa fa-times-circle-o" aria-hidden="true"></i>
            </div>
          </div>
          <div className="data">
            {caseStudyQuestionData.map((questionData) => (
              <div className="question" key={questionData.id}>
                {questionData.question}
                <div className="margin-top-3" />
              </div>
            ))}
          </div>
          {/* <div className="data">
            <div className="question">
              How do you feel when you look at this image?
              <div className="margin-top-1-5" />
              Do you feel that this image is relevant to your grief?
              <div className="margin-top-1-5" />
              If yes, how?? If not, why not?
              <div className="margin-top-1-5" />
              How does this differ from ways the dead are treated today?
              <div className="margin-top-1-5" />
              Would you feel it appropriate to honour the dead in this way- why or
              why not?
              <div className="margin-top-1-5" />
              What might the case study tell us about the dead person?
              <div className="margin-top-1-5" />
              What might the case study tell us about attitudes towards the
              afterlife?
              <div className="margin-top-1-5" />
              What might this tell us about the relationships between the living
              and the dead?
              <div className="margin-top-1-5" />
              Does it matter where you die? Does it matter where you are buried?
              <div className="margin-top-1-5" />
              What are your preferences? Who decides?
              <div className="margin-top-1-5" />
            </div>
          </div> */}

          {/* <div className="bottom" /> */}
        </div>



    </>
  );
};

export default CaseStudyQuestion;
