import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import React from "react";
import "./App.css";
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import Header from "./components/Header";
import Home from "./pages/Home";
import About from "./pages/About";
import HowToUse from "./pages/HowToUse";
import GroundRules from "./pages/GroundRules";
import SelfCare from "./pages/SelfCare";
import BereavementSupport from "./pages/BereavementSupport";
import ContactUs from "./pages/ContactUs";
import Feedback from "./pages/Feedback";
import Team from "./pages/Team";
import Menu from "./pages/Menu";
import ModuleItem from "./pages/ModuleItem";
import Modules from "./pages/Modules";
import Learning from "./pages/Learning";
import PersonalStories from "./pages/PersonalStories";
import PersonalStory from "./pages/PersonalStory";
import CaseStudies from "./pages/CaseStudies";
import CaseStudy from "./pages/CaseStudy";
import CaseStudyQuestion from "./pages/CaseStudyQuestion";
import MovingForward from "./pages/MovingForward";
import FurtherReading from "./pages/FurtherReading";
import PersonalResearch from "./pages/PersonalResearch";
import Favourites from "./pages/Favourites";
import FurtherReadingItem from "./pages/FurtherReadingItem";
import { AuthProvider } from "./contexts/AuthContext";
import PrivateRoute from "./pages/PrivateRoute";
import Introduction from "./pages/Introduction";
import Verification from "./pages/Verification";
import UnsubscribeEmail from "./pages/UnsubscribeEmail";
import AccessibilityStatement from "./pages/AccessibilityStatement";
import PrivacyStatement from "./pages/PrivacyStatement";
import CookieStatement from "./pages/CookieStatement";
import VerifyDevice from "./pages/VerifyDevice";

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <AuthProvider>
          <Switch>
            <Route path="/registration" component={Registration} />
            <Route path="/login" component={Login} />
            <Route path="/verification" component={Verification} />
            <Route path="/unsubscribeemail" component={UnsubscribeEmail} />
            <Route path="/verifyDevice" component={VerifyDevice} />
            <PrivateRoute path="/" exact component={Home} />
            <PrivateRoute path="/menu" component={Menu} />
            <PrivateRoute path="/about" component={About} />
            <PrivateRoute path="/howtouse" component={HowToUse} />
            <PrivateRoute path="/groundrules" component={GroundRules} />
            <PrivateRoute path="/selfcare" component={SelfCare} />
            <PrivateRoute
              path="/bereavementsupport"
              component={BereavementSupport}
            />
            <PrivateRoute path="/contactus" component={ContactUs} />
            <PrivateRoute path="/feedback" component={Feedback} />
            <PrivateRoute path="/team" component={Team} />
            <PrivateRoute path="/accessibilityStatement" component={AccessibilityStatement} />
            <PrivateRoute path="/privacyStatement" component={PrivacyStatement} />
            <PrivateRoute path="/cookiePolicy" component={CookieStatement} />
            <PrivateRoute path="/modules" exact component={Modules} />
            <PrivateRoute path="/modules/:id" exact component={ModuleItem} />
            <PrivateRoute path="/modules/:id/learning" component={Learning} />
            <PrivateRoute path="/personalStories" component={PersonalStories} />
            <PrivateRoute path="/personalStory" component={PersonalStory} />
            <PrivateRoute path="/caseStudies" component={CaseStudies} />
            <PrivateRoute path="/caseStudy/:id" component={CaseStudy} />
            <PrivateRoute
              path="/caseStudyQuestion"
              component={CaseStudyQuestion}
            />
            <PrivateRoute
              path="/personalResearch/:id"
              component={PersonalResearch}
            />
            <PrivateRoute path="/movingForward" component={MovingForward} />
            <PrivateRoute
              path="/furtherReading"
              exact
              component={FurtherReading}
            />
            <PrivateRoute
              path="/furtherReading/:id"
              component={FurtherReadingItem}
            />
            <PrivateRoute path="/favourites" component={Favourites} />
            <PrivateRoute path="/introduction" component={Introduction} />
          </Switch>
        </AuthProvider>
      </div>
    </Router>
  );
}

export default App;
