import { useState } from 'react'
import classes from './AnswerCard.module.css'

const AnswerCard = (props) => {

    const [isActive, setIsActive] = useState(false)

    const active = (props.type === 'full' ? classes.card : classes.flex) + ' ' + classes.active
    const inactive = (props.type === 'full' ? classes.card : classes.flex) + ' ' + classes.inactive

    const setClick = () => {
        // if the tag is clicked set isFiltered to true if not already true
        // if isFiltered already true then set it to false
        
        if (!isActive) {
            props.onClick(props.text, true, props.answer)
            setIsActive(true)
        } else {
            props.onClick(props.text, false, props.answer)
            setIsActive(false)
         }
    }


    return (
        <div className={isActive ? active : inactive} onClick = {setClick}>{props.text}</div>
    )
}

export default AnswerCard;