import BackButton from '../components/BackButton'
import MenuButtonInactive from '../components/MenuButtonInactive'
import IntroHeading from "../components/IntroHeading"
import VideoPlayer from "../components/VideoPlayer"
// import IntroDescription from "../components/IntroDescription";

const PersonalStory = (props) => {

    const personalStoryData = props.location.state

    return (
        <>
        <BackButton />
        <MenuButtonInactive />
        <div className = 'intro-div-surrounding'>
            <div className = 'padding-top-5' />
            <IntroHeading text = {personalStoryData.heading} />
            <div className = 'padding-top-5' />
            {/* <IntroDescription text = {personalStoryData.heading} /> */}
            <div dangerouslySetInnerHTML={{
                    __html: personalStoryData.content
                  }}></div>
            <VideoPlayer url = {personalStoryData.video_URL}/>
            
        </div>
        </>
    )

}

export default PersonalStory;