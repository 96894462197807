import Header from "../components/Header";
import { useRef, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import Error from "../components/Error";
import { Link, useHistory } from "react-router-dom";
import PropagateLoader from "react-spinners/PropagateLoader";
import ReCAPTCHA from "react-google-recaptcha";

export default function Registration() {
  const nameRef = useRef();
  const emailRef = useRef();
  const { signUp } = useAuth();
  const [err, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [captchaResult, setCaptchaResult] = useState();

  const resetError = () => {
    setError("");
    console.log("reset error");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      nameRef.current.value &&
      emailRef.current.value &&
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailRef.current.value) &&
      captchaResult
    ) {
      setLoading(true);
      signUp(emailRef.current.value, nameRef.current.value)
        .then((res) => {
          if (res.data.email) {
            localStorage.setItem("email", res.data.email)
            localStorage.setItem("show_casestudy_popup", true)
            // getUser()
            history.push("/verification");
          }
          setLoading(false);
        })
        .catch((err) => {
          if (err.response) {
            setError(
              "" +
                (err.response.data.first_name
                  ? err.response.data.first_name
                  : "") +
                (err.response.data.email ? err.response.data.email : "")
            );
          } else {
            // if the response of error is not there because of server down
            setError(
              "Server is too busy or cannot contacted please try again later"
            );
          }
          setLoading(false);
        });

      
    } else {
      if (
        (!emailRef.current.value ||
          !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailRef.current.value)) &&
        !nameRef.current.value
      )
        setError("Please enter your name and a valid email address");
      else if (
        !emailRef.current.value ||
        !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailRef.current.value)
      )
        setError("Please enter a valid email");
      else if (!captchaResult) 
        setError("Please verify that you are not a robot");
      else
        setError("Please enter your name");
    }
  };

  const handleRecaptcha = (value) => {

    fetch('https://cb-toolkit.bradford.ac.uk/api/auth/recaptcha/', {
      method: 'POST',
      body: JSON.stringify({ 'captcha_value': value }),
      headers: { 'Content-Type': 'application/json' }
    })
     .then(res => res.json())
     .then(data => {
       console.log(data.captcha.success)
       setCaptchaResult(data.captcha.success)
       setError()
     }) 
  }

  return (
    <>
      <Header />
      {
      loading ? <PropagateLoader color={'#ffffff'} loading={loading} size={15} />
      :
      <div className="center auth-surrounding">
        <div className="padding-top-5"></div>
        <div className="toolkit-title">Continuing Bonds Toolkit</div>
        <h1>Register</h1>
        <div className="margin-top-10"></div>
        <input
          type="text"
          placeholder="Name"
          ref={nameRef}
          aria-label="Name"
          id="name"
          onMouseDown={resetError}
        />
        <div className="margin-top-1"></div>
        <input
          type="email"
          placeholder="Email"
          ref={emailRef}
          aria-label="Email"
          id="email"
          onMouseDown={resetError}
        />
        <div className="margin-top-3"></div>
        <div className="re-captcha">
          <ReCAPTCHA 
            sitekey={process.env.REACT_APP_SITE_KEY} 
            onChange={handleRecaptcha}
          />
        </div>

        {err && (
          <div className="err">
            <div className="margin-top-1" />
            <Error header="Registration Error" content={err} />
          </div>
        )}
        <div className="margin-top-1"></div>
        <div className={err ? "hide-elm" : ""}>
          {

            <button disabled={loading} type="submit" onClick={handleSubmit}>
              Register
            </button>
          }
          
        </div>
        <div className="margin-top-10"></div>
        Already Registered?{" "}
        <span className="pointer">
          <Link to="/login">Login</Link>
        </span>
      </div>
}
    </>
  );
}

// export default Registration;
