import { useState, useEffect } from "react";
import axios from 'axios';
import MenuButtonInactive from "../components/MenuButtonInactive";
import BackButton from "../components/BackButton";
import IntroHeading from "../components/IntroHeading";
import IntroDescription from "../components/IntroDescription";
import AnswerCard from "../components/AnswerCard";
import AnswerCardSingle from "../components/AnswerCardSingle";
import Error from "../components/Error";
import ModalCommon from '../components/ModalCommon';
import { useHistory } from "react-router-dom";

const sectionSelected = []
const answerToDb = {}

const Feedback = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
        }, []
    )

    const [submitError, setSubmitError] = useState(null)
    const [showSuccess, setShowSuccess] = useState(false)
    // const [countStyle, setCountStyle] = useState('count-label')

    // Defining state variables for holding character count of input fields
    const [roleCount, setRoleCount] = useState('')
    const [sectionDetailCount, setSectionDetailCount] = useState('')
    const [usefulModule1Count, setUsefulModule1Count] = useState('')
    const [usefulModule2Count, setUsefulModule2Count] = useState('')
    const [usefulModule3Count, setUsefulModule3Count] = useState('')
    const [usefulModule4Count, setUsefulModule4Count] = useState('')
    const [usefulModule5Count, setUsefulModule5Count] = useState('')
    const [usefulCaseStudyCount, setUsefulCaseStudyCount] = useState('')
    const [usefulMovingForwardCount, setUsefulMovingForwardCount] = useState('')
    const [usefulToolkitCount, setUsefulToolkitCount] = useState('')
    const [usefulRecommendationCount, setUsefulRecommendationCount] = useState('')
    const [usefulBenificialCount, setUsefulBenificialCount] = useState('')
    const [usefulArchaeologyCount, setUsefulArchaeologyCount] = useState('')
    const [suggestionCount, setSuggestionCount] = useState('')
    const [hearAboutCount, setHearAboutCount] = useState('')
    const [genderCount, setGenderCount] = useState('')
    const [ageCount, setAgeCount] = useState('')
    const [ethnicityCount, setEthnicityCount] = useState('')
    const [relegionCount, setRelegionCount] = useState('')

    // Defining state variables for holding character count style of the label
    // const [roleCountStyle, setRoleCountStyle] = useState('')
    // const [sectionDetailCountStyle, setSectionDetailCountStyle] = useState('')
    // const [usefulModule1CountStyle, setUsefulModule1CountStyle] = useState('')
    // const [usefulModule2CountStyle, setUsefulModule2CountStyle] = useState('')
    // const [usefulModule3CountStyle, setUsefulModule3CountStyle] = useState('')
    // const [usefulModule4CountStyle, setUsefulModule4CountStyle] = useState('')
    // const [usefulModule5CountStyle, setUsefulModule5CountStyle] = useState('')
    // const [usefulCaseStudyCountStyle, setUsefulCaseStudyCountStyle] = useState('')
    // const [usefulMovingForwardCountStyle, setUsefulMovingForwardCountStyle] = useState('')
    // const [usefulToolkitCountStyle, setUsefulToolkitCountStyle] = useState('')
    // const [usefulRecommendationCountStyle, setUsefulRecommendationCountStyle] = useState('')
    // const [usefulBenificialCountStyle, setUsefulBenificialCountStyle] = useState('')
    // const [usefulArchaeologyCountStyle, setUsefulArchaeologyCountStyle] = useState('')
    // const [suggestionCountStyle, setSuggestionCountStyle] = useState('')
    // const [hearAboutCountStyle, setHearAboutCountStyle] = useState('')
    // const [genderCountStyle, setGenderCountStyle] = useState('')
    // const [ageCountStyle, setAgeCountStyle] = useState('')
    // const [ethnicityCountStyle, setEthnicityCountStyle] = useState('')
    // const [relegionCountStyle, setRelegionCountStyle] = useState('')

    const countStyle =  'count-label'
    const history = useHistory()
    let answers_1 = [
        'Module 1 – Intuitive vs Instrumental Grief',
        'Module 2 – Dual Process Model of Grief',
        'Module 3 – The Tasks of Mourning',
        'Module 4 – Continuing Bonds',
        'Module 5 – Anticipated Grief',
        'Case Studies',
        'Moving forward'
    ]

    let answers_2 = [
        'Strongly Agree',
        'Agree',
        'Neutral',
        'Disagree',
        'Strongly Disagree',
        'Not applicable'
    ]

    let answers_gender = [
        'Female',
        'Male',
        'Transgender',
        'Non-binary',
        'Prefer not to say'
    ]

    let answers_gender_assign = [
        'Yes',
        'No',
        'Prefer not to say'
    ]

    let answers_ethnicity = [
        'White (British / European)',
        'White other',
        'Asian / Asian British',
        'Black / African / Caribbean / Black British',
        'Arab',
        'Mixed',
        'Any other ethnic group',
        'Prefer not to say'
    ]

    let answers_relegion = [
        'Muslim',
        'Hindu',
        'Sikh',
        'Christianity (Catholic, Protestant or any other Christian denominations)',
        'Jewish',
        'Atheist',
        'Agnostic',
        'I am not religious',
        'Prefer not to say'
    ]

    let answers_hearus = [
        'A colleague',
        'My therapist',
        'An educator',
        'Internet search',
        'Word of mouth',
    ]

    const isUsefulQuestions = [
        {text: 'Module 1 – Intuitive vs Instrumental Grief section useful?', objName: 'useful_module_1', count: usefulModule1Count, countLabelId: 'usefulModule1Count'},
        {text: 'Module 2 – Dual Process Model of Grief section useful?', objName: 'useful_module_2', count: usefulModule2Count, countLabelId: 'usefulModule2Count'},
        {text: 'Module 3 – Tasks of Mourning section useful?', objName: 'useful_module_3', count: usefulModule3Count, countLabelId: 'usefulModule3Count'},
        {text: 'Module 4 – Continuing Bonds section useful?', objName: 'useful_module_4', count: usefulModule4Count, countLabelId: 'usefulModule4Count'},
        {text: 'Module 5 – Anticipated Grief section useful?', objName: 'useful_module_5', count: usefulModule5Count, countLabelId: 'usefulModule5Count'},
        {text: 'Case Studies section useful?', objName: 'useful_case_study', count: usefulCaseStudyCount, countLabelId: 'usefulCaseStudyCount'},
        {text: 'Moving Forward section useful?', objName: 'useful_moving_forward', count: usefulMovingForwardCount, countLabelId: 'usefulMovingForwardCount'},
        {text: 'The toolkit was easy to navigate?', objName: 'useful_toolkit', count: usefulToolkitCount, countLabelId: 'usefulToolkitCount'},
        {text: 'I would you recommend the toolkit to other professionals?', objName: 'useful_recommendation', count: usefulRecommendationCount, countLabelId: 'usefulRecommendationCount'},
        {text: 'The content of the toolkit is beneficial to the client / user?', objName: 'useful_benificial', count: usefulBenificialCount, countLabelId: 'usefulBenificialCount'},
        {text: 'Archaeology is a useful way to talk about death, dying, bereavement and grief?', objName: 'useful_archaeology', count: usefulArchaeologyCount, countLabelId: 'usefulArchaeologyCount'},
    ]

    const clickHandler = (text, isSelected, answer) => {
        //  for multiple selection options it will add all the selections if selected and remove if unselected

        if (isSelected) {

            if (answer === 'section')
                sectionSelected.push(text)

        } else {

            if (answer === 'section')
                sectionSelected.splice(sectionSelected.indexOf(text), 1)
        }

        // disable section details input if any of the option selected
        if (sectionSelected.length > 0) {
            document.getElementById('sectionDetail').hidden = true
        } else {
            document.getElementById('sectionDetail').hidden = false
        }

        
    }

    const singleAnswerSetter = (objName, answer) => {
        // setting object for sending to backend for single option questions

        answerToDb[objName] = answer

        // check if gender is provided in selection if not show the please specify input
        if (answerToDb['gender'])
            document.getElementById('gender').hidden = true
        else
            document.getElementById('gender').hidden = false

        // check if ethnicity is provided in selection if not show the please specify input
        if (answerToDb['ethnicity'])
            document.getElementById('ethnicity').hidden = true
        else
            document.getElementById('ethnicity').hidden = false

        // check if relegion is provided in selection if not show the please specify input
        if (answerToDb['relegion'])
            document.getElementById('relegion').hidden = true
        else
            document.getElementById('relegion').hidden = false

        // check if hearabout is provided in selection if not show the please specify input
        if (answerToDb['hearUs'])
            document.getElementById('hearabout').hidden = true
        else
            document.getElementById('hearabout').hidden = false
    }

    const postData = () => {
        // taking all the input and selections and make an object
        const data = {
            role: [document.getElementById('role').value, 'role_question', false],
            sectionSelected: [sectionSelected.length > 0 ? sectionSelected.join(', ') : document.getElementById('sectionDetail').value, 'section_question', false],
            // sectionDetail: document.getElementById('sectionDetail').value,
            isModule1Useful: [answerToDb['useful_module_1'], 'question_useful_module_1', false],
            isModule1UsefulMore: [document.getElementById('useful_module_1').value, 'question_useful_module_1', false],
            isModule2Useful: [answerToDb['useful_module_2'], 'question_useful_module_2', false],
            isModule2UsefulMore: [document.getElementById('useful_module_2').value, 'question_useful_module_2', false],
            isModule3Useful: [answerToDb['useful_module_3'], 'question_useful_module_3', false],
            isModule3UsefulMore: [document.getElementById('useful_module_3').value, 'question_useful_module_3', false],
            isModule4Useful: [answerToDb['useful_module_4'], 'question_useful_module_4', false],
            isModule4UsefulMore: [document.getElementById('useful_module_4').value, 'question_useful_module_4', false],
            isModule5Useful: [answerToDb['useful_module_5'], 'question_useful_module_5', false],
            isModule5UsefulMore: [document.getElementById('useful_module_5').value, 'question_useful_module_5', false],
            isCaseStudyUseful: [answerToDb['useful_case_study'], 'question_useful_case_study', false],
            isCaseStudyUsefulMore: [document.getElementById('useful_case_study').value, 'question_useful_case_study', false],
            isMovingForwardUseful: [answerToDb['useful_moving_forward'], 'question_useful_moving_forward', false],
            isMovingForwardUsefulMore: [document.getElementById('useful_moving_forward').value, 'question_useful_moving_forward', false],
            isToolkitEasyToNavigate: [answerToDb['useful_toolkit'], 'question_useful_toolkit', false],
            isToolkitEasyToNavigateMore: [document.getElementById('useful_toolkit').value, 'question_useful_toolkit', false],
            wouldYouRecommend: [answerToDb['useful_recommendation'], 'question_useful_recommendation', false],
            wouldYouRecommendMore: [document.getElementById('useful_recommendation').value, 'question_useful_recommendation', false],
            isTheContentBeneficial: [answerToDb['useful_benificial'], 'question_useful_benificial', false],
            isTheContentBeneficialMore: [document.getElementById('useful_benificial').value, 'question_useful_benificial', false],
            isArchaeologyUseful: [answerToDb['useful_archaeology'], 'question_useful_archaeology', false],
            isArchaeologyUsefulMore: [document.getElementById('useful_archaeology').value, 'question_useful_archaeology', false],
            suggestion: [document.getElementById('suggestion').value, 'question_suggestion', false],
            gender: [answerToDb['gender'] ? answerToDb['gender'] : document.getElementById('gender').value, 'question_gender', false],
            isGenderAssigned: [answerToDb['isGenderAssigned'], 'question_gender_assigned', false],
            age: [document.getElementById('age').value, 'question_age', false],
            ethnicity: [answerToDb['ethnicity'] ? answerToDb['ethnicity'] : document.getElementById('ethnicity').value, 'question_ethnicity', false],
            relegion: [answerToDb['relegion'] ? answerToDb['relegion'] : document.getElementById('relegion').value, 'question_relegion', false],
            hearabout: [answerToDb['hearUs'] ? answerToDb['hearUs'] : document.getElementById('hearabout').value, 'question_hearabout', false],
        }
        submit(data)
    }

    const submit = async (data) => {
        // post the data by making a postable object if all required fields are set
        // show error messages incase of empty fields for required data

        let isFormComplete = true
        let postBody = {}

        for (let [key, value] of Object.entries(data)) {

            if (!value[0] && value[2]) {
                isFormComplete = false;
                document.getElementById(value[1]).style.color = 'red'
            } else if (value[0] && value[2]) {

                document.getElementById(value[1]).style.color = 'white'
            }

            // setting object for sending as post body with all the keys having value or not
            //  since the error is handle seperately using isFormComplete variable it will also accept undefine
            postBody[key] = value[0]
        }
        
        // if incomplete form show error message otherwise redirecting to dashboard
        if (!isFormComplete)
            setSubmitError(true)
        else {
            setSubmitError(false)
            
            const config = {
                headers: {
                    "Authorization": "Token " + localStorage.getItem('token'),
                    "Content-Type": "application/json"
                },
            }

            try {
                await axios.post('/api/feedback/', postBody, config)
                console.log('success')
                setShowSuccess(true)
                localStorage.setItem('feedback', false)
                // setTimeout(() => {
                //     setShowSuccess(false)
                //     window.location.reload()
                // }, 30000)
            } catch (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                    setSubmitError(error.response.data)
                    setTimeout(() => {
                        setSubmitError(null)
                    }, 2000)

                  } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    // console.log(error.request);
                    // setSubmitError(error.message)
                    setSubmitError('Something went wrong. Please try again.')
                    setTimeout(() => {
                        setSubmitError(null)
                    }, 2000)
                  } else {
                    // Something happened in setting up the request that triggered an Error
                    // console.log('Error', error.message);
                    // setSubmitError(error.message)
                    setSubmitError('Something went wrong. Please try again.')
                    setTimeout(() => {
                        setSubmitError(null)
                    }, 2000)
                  }
                //   console.log(error.config);
            }
        }
    }

    const navigate = () => {
        history.push('/')
      }
    
    const setCharacterCounter = event => {
        //  get the element id of the input field and set the character counter for the lablel below
        const elementId = event.target.id
        const countValue = event.target.value.length + '/' + event.target.maxLength
        const isLimitReached = event.target.value.length === event.target.maxLength

        // count should be showed and the color of the lable will change if the limit reached
        if (elementId === 'role') {
            setRoleCount(countValue)
            document.getElementById('roleCount').style.color = isLimitReached ? 'red' : 'white'
        }
        else if (elementId === 'sectionDetail') {
            setSectionDetailCount(countValue)
            document.getElementById('sectionDetailCount').style.color = isLimitReached ? 'red' : 'white'
        }
        else if (elementId === 'useful_module_1') {
            setUsefulModule1Count(countValue)
            document.getElementById('usefulModule1Count').style.color = isLimitReached ? 'red' : 'white'
        }
        else if (elementId === 'useful_module_2') {
            setUsefulModule2Count(countValue)
            document.getElementById('usefulModule2Count').style.color = isLimitReached ? 'red' : 'white'
        }
        else if (elementId === 'useful_module_3') {
            setUsefulModule3Count(countValue)
            document.getElementById('usefulModule3Count').style.color = isLimitReached ? 'red' : 'white'
        }
        else if (elementId === 'useful_module_4') {
            setUsefulModule4Count(countValue)
            document.getElementById('usefulModule4Count').style.color = isLimitReached ? 'red' : 'white'
        }
        else if (elementId === 'useful_module_5') {
            setUsefulModule5Count(countValue)
            document.getElementById('usefulModule5Count').style.color = isLimitReached ? 'red' : 'white'
        }
        else if (elementId === 'useful_case_study') {
            setUsefulCaseStudyCount(countValue)
            document.getElementById('usefulCaseStudyCount').style.color = isLimitReached ? 'red' : 'white'
        }  
        else if (elementId === 'useful_moving_forward') {
            setUsefulMovingForwardCount(countValue)
            document.getElementById('usefulMovingForwardCount').style.color = isLimitReached ? 'red' : 'white'
        }
        else if (elementId === 'useful_toolkit') {
            setUsefulToolkitCount(countValue)
            document.getElementById('usefulToolkitCount').style.color = isLimitReached ? 'red' : 'white'
        }
        else if (elementId === 'useful_recommendation') {
            setUsefulRecommendationCount(countValue)
            document.getElementById('usefulRecommendationCount').style.color = isLimitReached ? 'red' : 'white'
        }
        else if (elementId === 'useful_benificial') {
            setUsefulBenificialCount(countValue)
            document.getElementById('usefulBenificialCount').style.color = isLimitReached ? 'red' : 'white'
        }
        else if (elementId === 'useful_archaeology') {
            setUsefulArchaeologyCount(countValue)
            document.getElementById('usefulArchaeologyCount').style.color = isLimitReached ? 'red' : 'white'
        }
        else if (elementId === 'suggestion') {
            setSuggestionCount(countValue)
            document.getElementById('suggestionCount').style.color = isLimitReached ? 'red' : 'white'
        }
        else if (elementId === 'gender') {
            setGenderCount(countValue)
            document.getElementById('genderCount').style.color = isLimitReached ? 'red' : 'white'
        }
        else if (elementId === 'age') {
            setAgeCount(countValue)
            document.getElementById('ageCount').style.color = isLimitReached ? 'red' : 'white'
        }
        else if (elementId === 'ethnicity') {
            setEthnicityCount(countValue)
            document.getElementById('ethnicityCount').style.color = isLimitReached ? 'red' : 'white'
        }
        else if (elementId === 'relegion') {
            setRelegionCount(countValue)
            document.getElementById('relegionCount').style.color = isLimitReached ? 'red' : 'white'
        }
        else if (elementId === 'hearabout') {
            setHearAboutCount(countValue)
            document.getElementById('hearaboutCount').style.color = isLimitReached ? 'red' : 'white'
        }
    }

    return (
        <>
            <MenuButtonInactive />
            <BackButton />
            <div className = 'team-body'>
                <div className = 'padding-top-5' />
                <IntroHeading text = 'Feedback' />
                <div className = 'margin-top-5' />
                <IntroDescription text = 'Evaluation Questions for Professionals' />
                <div className = 'margin-top-5' />
                <div>
                    <div><span className = 'bold' id = 'role_question'>What is your role? </span> <span className='feedback-section-description'>I.e counseller,  volunteer, client, curious individual </span> </div>
                    <div className = 'margin-top-1-5' />
                    <input id = 'role' maxLength={60} onChange = {setCharacterCounter}/>
                    <span id = 'roleCount' className={countStyle}>{roleCount}</span>

                    <div className = 'margin-top-2' />
                    <div className = 'bold' id = 'section_question'>Which section(s) have you been using? (Please tick all that apply) </div>

                    <div className = 'margin-top-1-5' />
                    {/* <AnswerCards cards = {answers_1} /> */}
                    <div className = 'case-study-filter-div'>
                        {
                            answers_1.map((value, index) => {
                                return <AnswerCard text = {value} type = 'full' onClick = {clickHandler} key = {index} answer = 'section' />
                            })
                        }
                    </div>
                    <div className = 'margin-top-1-5' />
                    <input id = 'sectionDetail' placeholder = 'Other (please specify)' maxLength={60} onChange = {setCharacterCounter}/>
                    <span id = 'sectionDetailCount' className={countStyle}>{sectionDetailCount}</span>

                    {
                        isUsefulQuestions.map((question, index) => {
                            return <div key = {index}>
                                <div className = 'margin-top-2' />
                                <div className = 'bold' id = {'question_' + question.objName}>{question.text}</div>
                                <div className = 'margin-top-1-5' />
                                <div className = 'case-study-filter-div'>
                                    <AnswerCardSingle answers = {answers_2} objName = {question.objName} onClick = {singleAnswerSetter} />
                                </div>
                                <div className = 'margin-top-1-5' />
                                <textarea id = {question.objName} placeholder = 'Feel free to tell us more about your answer' maxLength={400} onChange = {setCharacterCounter} />
                                <span id = {question.countLabelId} className={countStyle}>{question.count}</span>
                            </div>
                        })
                    }

                    <div className = 'margin-top-2' />
                    <div className = 'bold' id = 'question_suggestion'>Do you have any suggestions on how to improve the toolkit platform or content?</div>
                    <div className = 'margin-top-1-5' />
                    <textarea id = 'suggestion' maxLength={400} onChange = {setCharacterCounter} />
                    <span id = 'suggestionCount' className={countStyle}>{suggestionCount}</span>

                    <div className = 'margin-top-5' />
                    <div className='feedback-section-description'>
                        Please can you give us some information about you, so that we know who is using this toolkit?
                        <br />
                        This data is optional and will be used for assessing whether we need to make changes to be more inclusive. We are keen to make the toolkit as accessible as possible to all. Thank you for your time in answering these additional questions.
                    </div>

                    <div className = 'margin-top-2' />
                    <div className = 'bold' id = 'question_gender'>What gender do you identify as?</div>
                    <div className = 'margin-top-1-5' />
                    <div className = 'case-study-filter-div'>
                        <AnswerCardSingle answers = {answers_gender} objName = 'gender' onClick = {singleAnswerSetter} />
                    </div>
                    <div className = 'margin-top-1-5' />
                    <input id = 'gender' placeholder = 'Other (please specify)' maxLength={60} onChange = {setCharacterCounter}/>
                    <span id = 'genderCount' className={countStyle}>{genderCount}</span>

                    <div className = 'margin-top-2' />
                    <div className = 'bold' id = 'question_gender_assigned'>Are you the same gender as assigned at birth?</div>
                    <div className = 'margin-top-1-5' />
                    <div className = 'case-study-filter-div'>
                        <AnswerCardSingle answers = {answers_gender_assign} objName = 'isGenderAssigned' onClick = {singleAnswerSetter} />
                    </div>

                    <div className = 'margin-top-2' />
                    <div className = 'bold' id = 'question_age'>How old are you?</div>
                    <div className = 'margin-top-1-5' />
                    <input id = 'age' placeholder = '' type={'number'} max={"5"} maxLength={3}/>
                    <span id = 'ageCount' className={countStyle}>{ageCount}</span>

                    <div className = 'margin-top-2' />
                    <div className = 'bold' id = 'question_ethnicity'>How would you describe your ethnicity?</div>
                    <div className = 'margin-top-1-5' />
                    <div className = 'case-study-filter-div'>
                        <AnswerCardSingle answers = {answers_ethnicity} objName = 'ethnicity' onClick = {singleAnswerSetter} />
                    </div>
                    <div className = 'margin-top-1-5' />
                    <input id = 'ethnicity' placeholder = 'Other (please specify)' maxLength={60} onChange = {setCharacterCounter} />
                    <span id = 'ethnicityCount' className={countStyle}>{ethnicityCount}</span>

                    <div className = 'margin-top-2' />
                    <div className = 'bold' id = 'question_relegion'>What religious family do you belong to or identify yourself most closely with?</div>
                    <div className = 'margin-top-1-5' />
                    <div className = 'case-study-filter-div'>
                        <AnswerCardSingle answers = {answers_relegion} objName = 'relegion' onClick = {singleAnswerSetter} />
                    </div>
                    <div className = 'margin-top-1-5' />
                    <input id = 'relegion' placeholder = 'Other (please specify)' maxLength={60} onChange = {setCharacterCounter}/>
                    <span id = 'relegionCount' className={countStyle}>{relegionCount}</span>

                    <div className = 'margin-top-2' />
                    <div className = 'bold' id = 'question_hearabout'>How did you hear about the toolkit?</div>
                    <div className = 'margin-top-1-5' />
                    <div className = 'case-study-filter-div'>
                        <AnswerCardSingle answers = {answers_hearus} objName = 'hearUs' onClick = {singleAnswerSetter} />
                    </div>
                    <div className = 'margin-top-1-5' />
                    <input id = 'hearabout' placeholder = 'Other (please specify)' maxLength={60} onChange = {setCharacterCounter} />
                    <span id = 'hearaboutCount' className={countStyle}>{hearAboutCount}</span>

                </div>
                <div className = 'margin-top-5' />
                {
                    submitError && <><Error header = 'Something went wrong' content = { submitError.toString() } /> <div className = 'margin-top-1-5' /></>
                }
                <div className = 'center'>
                    <button onClick = {postData}>Submit</button>
                </div>
                {
                    showSuccess && 
                    <ModalCommon show={showSuccess} >
                        <p>Thank you for giving us your valuable feedback</p>
                        <div className="modal-button">
                            <button type="button" onClick={navigate}>Continue</button>
                        </div>
                    </ModalCommon>
                }
            </div>
        </>
    )
}

export default Feedback;
