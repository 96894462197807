import logo from "../images/cb_logo_white.png";
import classes from "./Header.module.css";

const Header = () => {
  return (
    <>
      <div className="center">
        <div className={classes.container}>
          <img
            className={classes.img}
            src={logo}
            alt="representing logo of Continuing Bonds"
          />
        </div>
      </div>
    </>
  );
};

export default Header;
