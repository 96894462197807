import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import Modal from '../components/Modal'
// import Feedback from '../pages/Feedback'

import { useHistory } from "react-router-dom";

const AuthContext = React.createContext()

export const useAuth = () => {
    return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {

    const [currentUser, setCurrentUser] = useState();
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(false)

    const history = useHistory();

    useEffect(() => {

        const getUser = async () => {

            // console.log(currentUser)
            // for current user it will return the user without talking to server
            // for current user if the feedback popup set true in server then after 15 sec it will redirect to feedback
            if (currentUser) {
                if (currentUser.show_feedback_popup) {
                    setTimeout(() => {
                        showModal()
                    }, 3000)
                }
                return currentUser
            }
            
            const token = localStorage.getItem('token') ? localStorage.getItem('token') : null
    
            if (token) {
                const config = {
                    headers: {
                        "Authorization": "Token " + token
                    }
                }
        
                axios.get('/api/auth/user', config).then(res => {
                    // console.log(token)
                    // console.log(res.data)
                    setCurrentUser(res.data);
                    return res.data
                }).catch(err => {
                    setCurrentUser()
                    localStorage.removeItem("token")
                })
            }
        }

        getUser()
        // signUp();
        setLoading(false)
    }, [currentUser, history])

    const signUp = async (email, first_name) => {
        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        }
        const body = JSON.stringify({email, first_name})
        return await axios.post('/api/auth/register', body, config)
    }

    const login = async (username, browser_fingerprint) => {
        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        }
        const body = JSON.stringify({username, browser_fingerprint})
        return await axios.post('/api/auth/login', body, config)
    }

    const logout = async () => { 
        const config = {
            headers: {
                "Authorization": "Token " + localStorage.getItem('token')
            }
        }

        axios.post('/api/auth/logout', null, config).then(res => {
            localStorage.clear();
            sessionStorage.clear();
            history.push("/login");
        }).catch((err) => {
            console.log('something fishy')
        });
    }

    const getData = url => {

        const config = {
            headers: {
                "Authorization": "Token " + localStorage.getItem('token')
            }
        }

        axios.get(url, config).then(res => {
            console.log(res.data)
            return res.data
        }).catch(err => {
            console.log('err')
        })
    }

    const showModal = () => {
        setModal(true)
    };
    
    const hideModal = () => {
        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        }
      
        const username = localStorage.getItem('email')
        const body = JSON.stringify({username})
      
        axios.put('/api/auth/feedback_popup', body, config).then((res) => {
            console.log(res.data) 
        }).catch((err) => {
            console.log(err)
        })

        setModal(false)
    };

    const value = {
        currentUser,
        // getUser,
        signUp,
        login,
        getData,
        logout
    }
    // console.log(error)
    return (
        
        <AuthContext.Provider value={value}>
            {!loading && children}
            <Modal show={modal} handleClose={hideModal}>
                <p>Are you happy to provide your valuable feedback now?</p>
            </Modal>
            {/* <button type="button" onClick={showModal}>
                Open
            </button> */}
        </AuthContext.Provider>
    )
}
