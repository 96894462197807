import { useState } from "react";
import About from './About'
import HowToUse from './HowToUse'
import GroundRules from './GroundRules'
import SelfCare from './SelfCare'
import { useHistory } from "react-router-dom";
import axios from 'axios';
import PropagateLoader from "react-spinners/PropagateLoader";

// once a user logged into the app first time this screen will be shown
// about us, how to use the toolkit, ground rules, self care these screens will be shown
// once the user go through these screens and shoult accept in the tick box to continue to dashboard

const Introduction = () => {

    const [currentScreen, setCurrentScreen] = useState(0);
    const [loading, setLoading] = useState(false);

    const screens = ['aboutus', 'howtouse', 'groundrules', 'selfcare']

    const history = useHistory();

    const increaseCount = () => {
        setCurrentScreen(currentScreen + 1)
        resetScroll()
    }

    const decreaseCount = () => {
        setCurrentScreen(currentScreen - 1)
        resetScroll()
    }

    const getStart = async (e) => {

        setLoading(true)

        e.preventDefault()

        const config = {
            headers: {
                "Content-Type": "application/json"
            }
          }
      
        const username = localStorage.getItem('email')
        const body = JSON.stringify({username})

        axios.put('/api/auth/verify_intro', body, config).then((res) => {
            localStorage.setItem("token", res.data.token);
            history.replace("/")
            setLoading(false)
        }).catch((err) => {

            console.log('Something wrong')
            setLoading(false)

        })
        
        
    }

    const resetScroll = () => {
        document.getElementById('intro-card').scrollTop = 0
    }

    return (
        <>
            {
            loading ? <PropagateLoader color={'#ffffff'} loading={loading} size={15} />
            :
            <div className = 'intro-container'>
                <div className="intro-card" id = 'intro-card'>
                    {
                        screens[currentScreen] === 'aboutus' ? <About></About> :
                        screens[currentScreen] === 'howtouse' ? <HowToUse></HowToUse> :
                        screens[currentScreen] === 'groundrules' ? <GroundRules></GroundRules>:
                        <SelfCare></SelfCare>
                    }
                    <div className = 'intro-nav'>
                        {currentScreen !== 0 && <button onClick = {decreaseCount}>Prev</button>}
                        {currentScreen !== screens.length - 1 && <button onClick = {increaseCount}>Next</button>}  
                        {currentScreen === screens.length - 1 && <button onClick = {getStart} className = 'intro-get-started'>Get Started</button>}
                    </div>
                    
                </div>
            </div>
            }
        </>

    )

}

export default Introduction
